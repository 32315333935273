export const SHOWN_MENU_STYLES = [
    { selector: '.menu .menu-item', styles: { paddingLeft: '23px' } },
    { selector: '.menu .menu-item .icon', styles: { marginRight: '10px' } },
    { selector: '.left-sidebar .show-hide-arrow', styles: { paddingLeft: '23px' } },
    { selector: '.left-sidebar', styles: { width: '162px' } },
    { selector: '.page-container', styles: { width: 'calc(100% - 162px)' } },
    { selector: '.left-sidebar .show-hide-arrow svg', styles: { transform: 'rotate(0deg)' } },
];

export const SHOWN_MENU_CALLBACK_STYLES = [
    { selector: '.menu .menu-item .title', styles: { display: 'block' } },
]

export const HIDDEN_MENU_STYLES = [
    { selector: '.menu .menu-item', styles: { paddingLeft: '14px' } },
    { selector: '.menu .menu-item .icon', styles: { marginRight: '14px' } },
    { selector: '.menu .menu-item .title', styles: { display: 'none' } },
    { selector: '.left-sidebar .show-hide-arrow', styles: { paddingLeft: '14px' } },
    { selector: '.left-sidebar', styles: { width: '49px' } },
    { selector: '.page-container', styles: { width: 'calc(100% - 49px)' } },
    { selector: '.left-sidebar .show-hide-arrow svg', styles: { transform: 'rotate(180deg)' } },
];