import React, {Component} from 'react';
import TopSidebar from "../components/common/page/TopSidebar";
import LeftSidebar from "../components/common/page/LeftSidebar";
import SecurityProvider from "./SecurityProvider";
import Modal from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import {connect} from "react-redux";
import {updateModalState} from "../redux/actions/common/modal";

class Page extends Component {
    render() {
        return (
            <SecurityProvider>
                <TopSidebar/>
                <div className={"flex-container"}>
                    <LeftSidebar/>
                    <div className={"page-container"}>
                        {this.props.children}
                    </div>
                </div>
                <Modal open={this.props.isOpen}
                       onClose={() => this.props.updateModalState(false)}
                       classNames={{
                           modal: "modal-window",
                           closeButton: "modal-close-button",
                           closeIcon: "modal-close-icon"
                       }}
                       center>
                    { this.props.content }
                </Modal>
            </SecurityProvider>
        )
    }
}

export default connect(state => state.modal, { updateModalState }) (Page);