import React, {Component} from 'react';
import DateRange from "./DateRange";

class Settings extends Component {
    render() {
        return (
            <div className={"settings-sidebar"}>
                <div className={"left"}>
                    <DateRange/>
                </div>
            </div>
        )
    }
}

export default Settings