import React, {Component} from 'react';
import Icon from "@iconify/react";
import calendarAlt from "@iconify/icons-dashicons/calendar-alt";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {LOCALE} from "../../../../constants/common";

class RangeItem extends Component {
    render() {
        return (
            <div className={"item calendar"}>
                <Icon icon={calendarAlt} />
                <DateRangePicker
                    calendarIcon={null}
                    className={"range-picker"}
                    calendarClassName={"calendar"}
                    clearIcon={null}
                    locale={LOCALE}
                    maxDate={new Date()}
                    onChange={this.props.onChange}
                    showLeadingZeros={true}
                    value={this.props.value}
                />
            </div>
        )
    }
}

export default RangeItem