import React, {Component} from 'react';
import logo from '../../../assets/img/logo.svg';

import { Icon } from "@iconify/react";
import bxsDownArrow from '@iconify/icons-bx/bxs-down-arrow';
import UserMenu from "./UserMenu";
import {toggleUserMenu} from "../../../assets/functions/userMenu";
import {connect} from "react-redux";
import emptyUser from "../../../assets/img/empty/user.svg";
import {createImagePath} from "../../../assets/functions/formatting";

class TopSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openMenu: false
        };

        this.toggleMenuState = this.toggleMenuState.bind(this);
    }

    toggleMenuState() {
        this.setState(
            state => ( {openMenu: !state.openMenu} ),
            () => toggleUserMenu(this.state.openMenu)
        );
    }

    render() {
        let user = this.props;
        let name = user.name || "Неизвестный пользователь";
        let avatar = createImagePath(user, emptyUser);

        return (
            <div className={"top-sidebar"}>
                <div className={"logo"}>
                    <img src={logo} alt={"OMV Neurobox"} />
                </div>

                <div className={"user-info"} onClick={ this.toggleMenuState }>
                    <div className={"avatar-container img-container"}>
                        <img src={avatar} alt={ name } />
                    </div>

                    <div className={"name"}>{name }</div>
                    <Icon icon={bxsDownArrow} className={"down-arrow"} />

                    <UserMenu />
                </div>
            </div>
        )
    }
}

export default connect(state => state.user ) (TopSidebar);