import {UPDATE_NEUROBOXES_LIST} from "../../actionsTypes";
import {getNeuroboxesList} from "../../../assets/axios/requests/common/neuroboxes";
import {handleError} from "../../../assets/functions/errors";
import {NEUROBOXES_LIST} from "../../../constants/errors";
import {updateModalState} from "./modal";

export function updateNeuroboxesList(list) {
    return {
        type: UPDATE_NEUROBOXES_LIST,
        value: list
    }
}

export function loadNeuroboxesList() {
    return async dispatch => {
        try {
            let response = await getNeuroboxesList();
            dispatch( updateNeuroboxesList(response.data) );
        } catch (error) {
            handleError(error, NEUROBOXES_LIST, (...args) => dispatch(updateModalState(...args)));
        }
    }
}