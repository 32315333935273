import React, {Component} from 'react';
import {USER_MENU_ITEMS} from "../../../constants/userMenu";
import Icon from "@iconify/react";
import {Link} from "react-router-dom";

class UserMenu extends Component {
    render() {
        return (
            <div className={"user-menu"}>
                <div>
                    {USER_MENU_ITEMS.map((menuItem, i) => {
                        let item =
                            <div key={i} className={"menu-item"} onClick={menuItem.onCLick}>
                                <div className="icon">
                                    <Icon icon={menuItem.icon}/>
                                </div>
                                <div className="title">
                                    {menuItem.title}
                                </div>
                            </div>;

                        return menuItem.path
                            ? <Link to={menuItem.path}>{item}</Link>
                            : item;
                    })}
                </div>
            </div>
        )
    }
}

export default UserMenu