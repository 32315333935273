import {INITIAL_STORE_STATE} from "../initialStore";
import * as types from "../actionsTypes";

export default function userReducer(state = INITIAL_STORE_STATE.user, action) {
    switch (action.type) {
        case types.UPDATE_USER_INFO:
            return action.value
        default:
            return state;
    }
}