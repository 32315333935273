import {errorsList} from "../../constants/errors";
import React from 'react';

export function handleError(err, messageKey, handler, redirect = true) {
    console.dir(err);
    if (err?.response?.status === 401 && redirect) {
        window.location.href = "/auth";
    } else {
        handler(true,
            <div className={"error-alert"}>
                {errorsList[messageKey]}<br/>{err.toString()}
            </div>);
    }
}