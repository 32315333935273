import React, {Component} from 'react';
import logo from "../assets/img/logo.svg";

class AuthTablet extends Component {
    render() {
        return (
            <div className={"tablet"}>
                <img src={logo} alt={"OMV Neurobox"} />
                <div className={"header"}>{this.props.header}</div>

                {this.props.children}
            </div>
        )
    }
}

export default AuthTablet