import React, {Component} from 'react';
import {connect} from "react-redux";
import {loadUserInfo} from "../redux/actions/user";
import Loader from "../components/common/Loader";

class SecurityProvider extends Component {
    componentDidMount() {
        this.props.loadUserInfo();
    }

    render() {
        if (this.props.user) {
            return this.props.children;
        } else {
            return <Loader />;
        }
    }
}

export default connect(state => state, { loadUserInfo }) (SecurityProvider);