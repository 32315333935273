import React, {Component} from 'react';
import {connect} from "react-redux";
import {updateEventsDateRange} from "../../../redux/actions/events/settings/dateRange";
import RangeItem from "../../common/page/settings/RangeItem";
import {updateEventsList} from "../../../redux/actions/common/events";
import {clearEventsTopOffset} from "../../../redux/actions/events";
import {updateEventsPortionCount} from "../../../redux/actions/events/portionCount";

class DateRange extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(date) {
        this.props.updateEventsDateRange(date);
        this.props.updateEventsList([]);
        this.props.clearEventsTopOffset();
        this.props.updateEventsPortionCount(0);
    }

    render() {
        return (
            <RangeItem onChange={this.onChange}
                       value={this.props.settings.dateRange}/>
        )
    }
}

export default connect(state => state.events, {
    updateEventsDateRange,
    updateEventsList,
    clearEventsTopOffset,
    updateEventsPortionCount
}) (DateRange);