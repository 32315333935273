import { Controls } from "./mapObjects";

export function mapCanvasControlsToCanvasConfig(controls) {
    if (!controls || controls.length === 0) return [];
    const hasArrow = controls.some((control) => control.includes("arrow"));
    const config = controls.map((control) => Controls[control]);
    if (hasArrow) config.push(Controls["arrow"]);
    config.push(Controls["move"]);
    config.push(Controls["clear"]);
    return config;
}

export function mapRowsToServerFormat(rows, scale) {
    const result = rows.map((row) => ({
        color: row.color.slice(1),
        type: row.type,
        dots: row.dots.reduce((acc, dot) => {
            acc.push(Math.round(dot.x * scale));
            acc.push(Math.round(dot.y * scale));
            return acc;
        }, []),
        arrow: row.arrow.dots?.reduce((acc, dot) => {
            acc.push(Math.round(dot.x * scale));
            acc.push(Math.round(dot.y * scale));
            return acc;
        }, []),
    }));
    if (!result.arrow) delete result.arrow;
    return result;
}

function mapDots(array) {
    const result = [];
    for (let i = 0; i < array.length; i += 2) {
        result.push({ x: array[i], y: array[i + 1] });
    }
    return result;
}

export function mapServerRowsToState(rows) {
    return rows.map((row) => ({
        type: row.type,
        color: "#" + row.color,
        dots: mapDots(row.dots),
        arrow: {
            exists: Boolean(row.arrow),
            dots: Boolean(row.arrow) ? mapDots(row.arrow) : null,
        },
    }));
}
