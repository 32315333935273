import React, {Component} from 'react';
import {Draggable} from "react-draggable-hoc";
import Item from "../../common/detectors/Item";

class DraggableItem extends Component {
    render() {
        let { detector, withToolbar } = this.props;
        return (
            <Draggable dragProps={detector} delay={0}>
                <Item detector={detector} withToolbar={withToolbar}/>
            </Draggable>
        )
    }
}

export default DraggableItem