import React, { Component } from "react";
import { connect } from "react-redux";
import { updateMenuState } from "../../redux/actions/menu";
import styles from "./styles/Stats.module.css";

class Stats extends Component {
    componentDidMount() {
        this.props.updateMenuState();
    }

    render() {
        return (
            <div className={styles.wrapper}>
                <iframe
                    src="https://neurocloud.omegabot.ru/grafana/d/qr651G_Gk/detektor-peresecheniia-linii?orgId=1"
                    frameBorder="0"
                    allowFullScreen={false}
                    loading="lazy"
                    referrerPolicy="no-referrer"
                    sandbox="allow-same-origin allow-scripts allow-forms"
                    name="grafana-dashboard"
                    title="Grafana Dashboard"
                    className={styles.dashboard}
                />
            </div>
        );
    }
}

export default connect((state) => state, { updateMenuState })(Stats);
