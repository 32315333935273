import React, {Component} from 'react';
import {createImagePath} from "../../../../assets/functions/formatting";
import emptyDetector from "../../../../assets/img/empty/detector.svg";

class Header extends Component {
    render() {
        let { detector } = this.props;
        let icon = createImagePath(detector, emptyDetector);

        return (
            <div className={"header"}>
                <div className={"icon"}>
                    <img src={icon}
                         alt={ detector?.name || "Неизвестный детектор" }/>
                </div>
                <div className={"name"}>
                    { detector?.name || "Неизвестный детектор" }
                </div>
            </div>
        )
    }
}

export default Header