import {changeElementStyle} from "./DOMInteraction";
import {HIDDEN_MENU_STYLES, SHOWN_MENU_CALLBACK_STYLES, SHOWN_MENU_STYLES} from "../../constants/menu";

export function toggleMenu(open) {
    let menuStyles = open ? SHOWN_MENU_STYLES : HIDDEN_MENU_STYLES;
    let showHideArrow = document.querySelector('.left-sidebar .show-hide-arrow');

    function openMenuHandler() {
        showHideArrow.removeEventListener('transitionend', openMenuHandler);
        SHOWN_MENU_CALLBACK_STYLES.forEach( style => changeElementStyle(style) );
    }

    if (open) {
        showHideArrow.addEventListener('transitionend', openMenuHandler);
    } else {
        showHideArrow.removeEventListener('transitionend', openMenuHandler);
    }

    menuStyles.forEach( style => changeElementStyle(style) );
}

