import React, {Component} from 'react';
import {connect} from "react-redux";
import {loadDetectorsList} from "../../../redux/actions/common/detectors";
import Block from "../../../wrappers/Block";

class List extends Component {
    componentDidMount() {
        this.props.loadDetectorsList();
    }

    render() {
        let detectors = this.props.list;

        return (
            <Block header={"Детекторы"}>
                <div className="detectors container">
                    {
                        detectors.map(detector => this.props.children(detector))
                    }
                </div>
            </Block>
        )
    }
}

export default connect(state => state.configuration.detectors,
    { loadDetectorsList }) (List);