export function validateCameraName(value) {
    let error;
    if (!value) {
        error = "Введите название камеры";
    }
    return error;
}

export function validateCameraAddress(value) {
    let error;
    if (!value) {
        error = "Введите адрес камеры";
    }
    return error;
}
