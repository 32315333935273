import React, {Component} from 'react';
import Icon from "@iconify/react";
import bxsDownArrow from "@iconify/icons-bx/bxs-down-arrow";
import {toggleFiltersMenu} from "../../../../../assets/functions/filtersMenu";

class Parameter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openMenu: false
        };

        this.toggleMenuState = this.toggleMenuState.bind(this);
    }

    toggleMenuState(parent) {
        this.setState(
            state => ( {openMenu: !state.openMenu} ),
            () => toggleFiltersMenu(this.state.openMenu, parent)
        );
    }

    render() {
        return (
            this.props.items?.length
                ? <div className={"parameter"}
                       onClick={event => event.stopPropagation()}>
                    <div className={"name"}
                         onClick={ event => {
                             this.toggleMenuState(event.target.closest(".parameter"));
                             event.stopPropagation();
                         } }>
                        {this.props.name} <Icon icon={bxsDownArrow} className={"down-arrow"} />
                    </div>

                    <div className={"container"}>
                        {
                            this.props.items.map(item =>
                                <div key={item.id}
                                     className={`item ${this.props.selectedItem === item.id ? "selected" : ""}`}
                                     onClick={() => this.props.onSelect(item.id, this.props.selectedItem === item.id)}>

                                    <div className={"text"}>
                                        <div className={"radio"}><div/></div>
                                        { item.name }
                                    </div>

                                </div>
                            )
                        }
                    </div>
                </div>
                : null
        )
    }
}

export default Parameter