import {INITIAL_STORE_STATE} from "../initialStore";
import * as types from "../actionsTypes";

export function shopReducer(state = INITIAL_STORE_STATE.shop, action) {
    switch (action.type) {
        default:
            return Object.assign({}, state,
                { detectors: shopDetectorsReducer(state.detectors, action) });
    }
}

export function shopDetectorsReducer(state = INITIAL_STORE_STATE.shop.detectors, action) {
    switch (action.type) {
        case types.UPDATE_DETECTOR_DETAILS:
            return Object.assign({}, state, {
                selected: action.value.id,
                details: action.value
            });

        default:
            return state;
    }
}