import React, {Component} from 'react';
import {connect} from "react-redux";
import {updateMenuState} from "../../redux/actions/menu";
import Content from "../../wrappers/Content";
import List from "../common/events/List";
import {loadMoreEvents, loadTopEvents} from "../../redux/actions/events";
import Details from "./details";
import ClickableItem from "./ClickableItem";
import LazyScroll from "../../wrappers/LazyScroll";
import {EVENTS_PORTION_SIZE} from "../../constants/events";
import Settings from "./settings";
import {loadEventsList} from "../../redux/actions/common/events";

class Events extends Component {
    componentDidMount() {
        this.props.updateMenuState();
        this.timer = setInterval(() => {
            // this.props.loadEventsList();
            this.props.loadTopEvents();

        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        return (
            <>
                <Settings/>

                <Content>
                    <div className={"block-grid events"}>
                        <List>
                            <LazyScroll loadMore={this.props.loadMoreEvents}
                                        hasMore={this.props.list.length >= EVENTS_PORTION_SIZE * this.props.portionCount}>
                            {
                                this.props.list.map(event =>
                                    <ClickableItem key={event.id} event={event} />
                                    )
                            }
                            </LazyScroll>
                        </List>
                        { this.props.details ? <Details/> : null }
                    </div>
                </Content>
            </>
        )
    }
}

export default connect(state => state.events,
    {
        updateMenuState,
        loadEventsList,
        loadTopEvents,
        loadMoreEvents
    }) (Events);