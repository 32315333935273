import React, { Component } from "react";

class Block extends Component {
    render() {
        let { header, button } = this.props;

        return (
            <div className={`block ${this.props.className || ""}`}>
                <div className={"header"}>
                    <div>{header}</div>
                    {button}
                </div>

                {this.props.children}
            </div>
        );
    }
}

export default Block;
