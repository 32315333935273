import {getEventsList} from "../../../assets/axios/requests/events";
import {updateEventsPortionCount} from "../events/portionCount";
import {store} from "../../initialStore";
import {EVENTS_CURRENT_TIME, EVENTS_PORTION_SIZE, PORTION_EVENTS_KEY, TOP_EVENTS_KEY} from "../../../constants/events";
import {updateEventsTopOffset} from "../events";
import {UPDATE_EVENTS_LIST} from "../../actionsTypes";
import {handleError} from "../../../assets/functions/errors";
import {EVENTS_LIST} from "../../../constants/errors";
import {updateModalState} from "./modal";

export function updateEventsList(list) {
    return {
        type: UPDATE_EVENTS_LIST,
        value: list
    }
}

export function loadEventsList() {
    let events = store.getState().events;
    let settings = events.settings;
    let dateRange = settings.dateRange;
    let [ dateStart ] = dateRange;
    let portionCount = events.portionCount;

    let params = {
        limit: EVENTS_PORTION_SIZE * portionCount,
        offset: 0,
        filters: settings.filters,
        custom: { dateStart, dateEnd: EVENTS_CURRENT_TIME },
        orderBy: {'time': 'DESC'}
    }

    return getAndUpdateEventsList(params, updateEventsList);
}

export function getAndUpdateEventsList(params, updater, options = {}) {
    return async dispatch => {
        if (options?.key === PORTION_EVENTS_KEY) {
            dispatch( updateEventsPortionCount(options?.data?.page || 0) );
        }

        try {
            let response = await getEventsList(params);
            let events = store.getState().events;

            let data = options?.responseConcat === "APPEND"
                ? events.list.concat(response.data)
                : (options?.responseConcat === "PREPEND"
                    ? response.data.reverse().concat(events.list)
                    : response.data);

            dispatch( updater(data) );

            if (options?.key === TOP_EVENTS_KEY) {
                dispatch( updateEventsTopOffset(response.data.length) );
            }
        } catch (error) {
            handleError(error, EVENTS_LIST, (...args) => dispatch(updateModalState(...args)))
        }
    }
}