import {UPDATE_EVENTS_FILTERS_NEUROBOX, UPDATE_EVENTS_FILTERS_TYPE} from "../../../actionsTypes";

export function updateEventsFiltersNeurobox(id) {
    return {
        type: UPDATE_EVENTS_FILTERS_NEUROBOX,
        value: id
    }
}

export function updateEventsFiltersType(id) {
    return {
        type: UPDATE_EVENTS_FILTERS_TYPE,
        value: id
    }
}