import React, {Component} from 'react';
import DroppableItem from "./DroppableItem";
import List from "../../common/neuroboxes/List";

class Neuroboxes extends Component {
    render() {
        return (
            <List>
                { neurobox => <DroppableItem neurobox={neurobox} key={neurobox.id} /> }
            </List>
        )
    }
}

export default Neuroboxes;