import React, {Component} from 'react';

class DetectorSettingsType extends Component {
    render() {
        return (
            <div className={"settings"}>
                <div className={"name"}>{ this.props.name }</div>
                <div className={"fields"}>
                    {
                        this.props.children
                    }
                </div>
            </div>
        )
    }
}

export default DetectorSettingsType