import {INITIAL_STORE_STATE} from "../initialStore";
import * as types from "../actionsTypes";

export function eventsReducer(state = INITIAL_STORE_STATE.events, action) {
    switch (action.type) {
        case types.UPDATE_EVENTS_LIST:
            return Object.assign({}, state, {
                list: action.value
            });

        case types.UPDATE_EVENT_DETAILS:
            return Object.assign({}, state, {
                details: action.value,
                selected: action.value
            });

        case types.UPDATE_EVENTS_PORTION_COUNT:
            return Object.assign({}, state, {
                portionCount: action.value
            });

        case types.UPDATE_EVENTS_TOP_OFFSET:
            return Object.assign({}, state, {
                topOffset: state.topOffset +  action.value
            });
        case types.CLEAR_EVENTS_TOP_OFFSET:
            return Object.assign({}, state, {
                topOffset: 0
            });
        default:
            return Object.assign({}, state,
                { settings: eventsSettingsReducer(state.settings, action) });

    }
}

export function eventsSettingsReducer(state = INITIAL_STORE_STATE.events.settings, action) {
    switch (action.type) {
        case types.UPDATE_EVENTS_DATE_RANGE:
            return Object.assign({}, state, { dateRange: action.value });

        default:
            return Object.assign({}, state,
                { filters: eventsFiltersReducer(state.filters, action) });
    }
}

export function eventsFiltersReducer(state = INITIAL_STORE_STATE.events.settings.filters, action) {
    switch (action.type) {
        case types.UPDATE_EVENTS_FILTERS_NEUROBOX:
            return Object.assign({}, state, { neurobox: action.value });

        case types.UPDATE_EVENTS_FILTERS_TYPE:
            return Object.assign({}, state, { type: action.value });

        default: return state;
    }
}