import React, {Component} from 'react';
import {Droppable} from "react-draggable-hoc";
import Item from "../../common/neuroboxes/Item";
import {CAMERA} from "../../../constants/objectTypes";
import {attachCamera} from "../../../assets/axios/requests/configuration/cameras";
import {handleError} from "../../../assets/functions/errors";
import {CAMERA_ATTACHMENT} from "../../../constants/errors";
import {connect} from "react-redux";
import {updateModalState} from "../../../redux/actions/common/modal";

class DroppableItem extends Component {
    render() {
        let { neurobox } = this.props;

        return (
            <Droppable
                withDragProps={false}
                onDrop={async ({ dragProps }) => {
                    if (dragProps.objectType === CAMERA) {
                        try {
                            let agree = window.confirm(`Вы уверены, что хотите прикрепить камеру ${dragProps.name} на Нейробокс ${neurobox.name}?`);
                            if (agree) {
                                await attachCamera(neurobox.id, dragProps.id);
                            }
                        } catch (error) {
                            handleError(error, CAMERA_ATTACHMENT, this.props.updateModalState)
                        }
                    }
                }}
            >
                {({isHovered, ref}) => (
                    <div ref={ref} className={'droppable'}>
                        <Item editable={true} isHovered={isHovered} neurobox={neurobox} />
                    </div>
                )}
            </Droppable>
        )
    }
}

export default  connect(() => ({}), { updateModalState }) (DroppableItem)