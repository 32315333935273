import React, {Component} from 'react';
import { Icon } from "@iconify/react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class Menu extends Component {
    render() {
        return (
            <div className={"menu"}>
                {
                    this.props.menuItems.map((menuItem, i) =>
                        <Link key={i}
                              to={menuItem.path}>

                            <div className={"menu-item " + (menuItem.active ? "active" : "")}
                                 title={menuItem.title}>
                                <div className={"icon"}>
                                    <Icon icon={menuItem.icon} className={"down-arrow"} />
                                </div>

                                <div className={"title"}>{ menuItem.title }</div>

                                {menuItem.active ? <div className={"arrow"}> </div> : ""}
                            </div>

                        </Link>
                    )
                }
            </div>
        )
    }
}

export default connect(state => state) (Menu);