import { useCallback, useState } from "react";
import {
    checkLineForSelfIntersection,
    checkShapeForConvexity,
    doesRowContainArrow,
    isPointInsideArea,
    removeItemAtIndex,
} from "../utils";

export const useRowsValidationByType = () => {
    const [errors, setErrors] = useState([]);

    const getFlatArrayOfErrors = useCallback(() => {
        return errors.reduce(
            (acc, item, idx) =>
                (acc = [...acc, ...item.map((fn) => fn(idx + 1))]),
            []
        );
    }, [errors]);

    const addEmptyErrors = useCallback((rowsCount) => {
        setErrors((prev) => [...prev, ...new Array(rowsCount).fill([])]);
    }, []);

    const checkFigures = useCallback((row, index) => {
        const activeRowDots = row?.dots;
        const activeRowType = row?.type;
        const activeRowArrow = row?.arrow;
        const errors = [];

        if (activeRowType === "line") {
            if (checkLineForSelfIntersection(activeRowDots)) {
                errors.push((index) => `Линия ${index} пересекает саму себя`);
            }

            if (activeRowDots.length < 2) {
                errors.push(
                    (index) =>
                        `Линия ${index} должна состоять минимум из 2 точек`
                );
            }
        }

        if (
            activeRowType === "area" ||
            activeRowType === "arrow_area" ||
            activeRowType === "speed_arrow_area"
        ) {
            if (!checkShapeForConvexity(activeRowDots)) {
                errors.push((index) => `Область ${index} должна быть выпуклой`);
            }

            if (activeRowDots.length < 3) {
                errors.push(
                    (index) =>
                        `Область ${index} должна состоять минимум из 3 точек`
                );
            }
        }

        if (activeRowType?.includes("arrow")) {
            if (!activeRowArrow?.dots[1]) {
                errors.push(
                    (index) =>
                        `Область ${index} должна содержать направление, состоящее из 2 точек`
                );
            }

            if (doesRowContainArrow(row)) {
                const isArrowInsideArea =
                    isPointInsideArea(activeRowArrow?.dots[0], activeRowDots) &&
                    isPointInsideArea(activeRowArrow?.dots[1], activeRowDots);

                if (!isArrowInsideArea) {
                    errors.push(
                        (index) =>
                            `Направление должно находиться внутри активной области ${index}`
                    );
                }
            }
        }

        setErrors((prev) => {
            prev[index] = errors;
            return [...prev];
        });
    }, []);

    const removeErrorAtIndex = useCallback((index) => {
        setErrors((prev) => removeItemAtIndex(prev, index));
    }, []);

    return {
        errors,
        addEmptyErrors,
        removeErrorAtIndex,
        checkFigures,
        getFlatArrayOfErrors,
    };
};
