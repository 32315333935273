import {SERVER_HOST} from "../../constants/common";
import workIcon from "../img/neuroStatuses/work.svg";
import updateIcon from "../img/neuroStatuses/update.svg";
import errorIcon from "../img/neuroStatuses/error.svg";

export function createImagePath(entity, imgStub) {
    return entity?.image?.imagesDirectory && entity?.image?.path
        ? (SERVER_HOST + entity.image.imagesDirectory + entity.image.path)
        : imgStub;
}

export function formatNeuroboxStatus(status) {
    switch (status) {
        case "work": return "работает";
        case "wait_connection":
        case "update": return "загрузка";
        case "error":
        default: return "ошибка";
    }
}

export function formatNeuroboxStatusIcon(status) {
    switch (status) {
        case "work": return workIcon;
        case "wait_connection":
        case "update": return updateIcon;
        case "error":
        default: return errorIcon;
    }
}