import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk'
import reducer from "./reducers";
import viewDashboard from "@iconify/icons-mdi/view-dashboard";
import wrenchIcon from "@iconify/icons-mdi/wrench";
import cogIcon from "@iconify/icons-mdi/cog";
import databaseIcon from "@iconify/icons-mdi/database";
import accountMultiple from "@iconify/icons-mdi/account-multiple";
import chartAreasplineVariant from "@iconify/icons-mdi/chart-areaspline-variant";
import ticketIcon from '@iconify/icons-mdi/ticket';
import cartIcon from '@iconify/icons-mdi/cart';
import Login from "../components/auth/formViews/Login";
import React from "react";

let dateStart = new Date();
dateStart.setHours(0,0, 0, 0);
let dateEnd = new Date();
dateEnd.setHours(23,59,59, 999);

export const INITIAL_STORE_STATE =
    {
        user: null,
        authTabletView: <Login/>,
        menuItems: [
            { path: '/dashboard', title: 'Дашборд', icon: viewDashboard, active: false },
            { path: '/events', title: 'События', icon: ticketIcon, active: false },
            { path: '/configuration', title: 'Конфигурация', icon: wrenchIcon, active: false },
            { path: '/settings', title: 'Настройки', icon: cogIcon, active: false },
            { path: '/database', title: 'База данных', icon: databaseIcon, active: false },
            { path: '/users', title: 'Пользователи', icon: accountMultiple, active: false },
            { path: '/stats', title: 'Статистика', icon: chartAreasplineVariant, active: false },
            { path: '/shop', title: 'Магазин', icon: cartIcon, active: false },
        ],
        modal: {
            isOpen: false,
            content: ""
        },

        dashboard: {
            widgets: {
                events: {
                    list: []
                }
            },
            settings: {
                dateRange: [dateStart, dateEnd]
            }
        },
        events: {
            list: [],
            selected: null,
            details: null,
            portionCount: 0,
            topOffset: 0,
            settings: {
                dateRange: [dateStart, dateEnd],
                filters: {}
            }
        },
        configuration: {
            neuroboxes: {
                list: []
            },
            cameras: {
                list: []
            },
            detectors: {
                list: []
            }
        },
        shop: {
            detectors: {
                selected: null,
                details: null
            }
        }
    };

export const store = applyMiddleware(thunk) (createStore) (reducer);