import { Field, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { createImagePath } from "../../../../../../assets/functions/formatting";
import emptyShot from "../../../../../../assets/img/empty/shot.svg";
import {
    mapCanvasControlsToCanvasConfig,
    mapRowsToServerFormat,
    mapServerRowsToState,
    RowIcons,
    RowLabels,
} from "../../canvas/config";
import { useRowsValidationByType } from "../../canvas/hooks/useRowsValidationByType";
import {
    canRowContainsArrow,
    clearCanvas,
    doesRowContainArrow,
    drawDots,
    drawFigure,
    drawImage,
    findIndexOfApproximatePoint,
    genArrayOfHexColors,
    getPointCoordinatesRelativeToCanvas,
    insertItemAtIndex,
    isClearMode,
    isDrawingArrow,
    isDrawingMode,
    isMoveMode,
    isPointInsideArea,
    removeItemAtIndex,
    scaleDots,
} from "../../canvas/utils";

function CanvasField(props) {
    const form = useFormikContext();
    const canvasRef = useRef(null);
    const canvasWidth = 600;
    const [canvasHeight, setCanvasHeight] = useState(0);
    const colors = genArrayOfHexColors(2);
    const scalingFactor = useRef(1);
    const image = useRef(new Image());
    const [moveablePointIndex, setMoveablePointIndex] = useState(-1);
    const [moveableArrowPointIndex, setMoveableArrowPointIndex] = useState(-1);
    const [mode, setMode] = useState("idle");
    const [activeRowIdx, setActiveRowIdx] = useState(null);
    const [rows, setRows] = useState(
        form.values[props.code]?.length > 0
            ? mapServerRowsToState(form.values[props.code])
            : []
    );

    const {
        errors,
        checkFigures,
        getFlatArrayOfErrors,
        removeErrorAtIndex,
        addEmptyErrors,
    } = useRowsValidationByType();

    useEffect(() => {
        image.current.src = createImagePath(props.camera, emptyShot);
        image.current.onload = () => {
            canvasRef.current.height =
                image.current.height *
                (canvasRef.current.width / image.current.width);
            scalingFactor.current =
                image.current.width / canvasRef.current.width;
            drawImage(canvasRef.current, image.current);
            setCanvasHeight(canvasRef.current.height);
            setRows((prev) => {
                addEmptyErrors(prev.length);
                return prev.map((row) => ({
                    ...row,
                    label: RowLabels[row.type],
                    icon: RowIcons[row.type],
                    dots: scaleDots(row.dots, scalingFactor.current),
                    arrow: {
                        ...row.arrow,
                        dots: Array.isArray(row.arrow.dots)
                            ? scaleDots(row.arrow.dots, scalingFactor.current)
                            : null,
                    },
                }));
            });
        };
    }, [addEmptyErrors, props.camera]);

    const repaintCanvas = () => {
        clearCanvas(canvasRef.current);
        drawImage(canvasRef.current, image.current);

        rows.forEach((row) => {
            drawDots(canvasRef.current, row.dots, row.color);
            drawFigure(canvasRef.current, row);
        });
    };

    const handleMouseMove = (e) => {
        if (isMoveMode(mode)) {
            if (moveablePointIndex === -1 && moveableArrowPointIndex === -1) {
                return;
            }

            const point = getPointCoordinatesRelativeToCanvas(
                canvasRef.current,
                e.clientX,
                e.clientY
            );

            const updatedRow = { ...rows[activeRowIdx] };

            if (moveablePointIndex !== -1) {
                updatedRow.dots[moveablePointIndex].x = point.x;
                updatedRow.dots[moveablePointIndex].y = point.y;
            }

            if (moveableArrowPointIndex !== -1) {
                updatedRow.arrow.dots[moveableArrowPointIndex].x = point.x;
                updatedRow.arrow.dots[moveableArrowPointIndex].y = point.y;
            }

            setRows((prev) => {
                return insertItemAtIndex(
                    removeItemAtIndex(prev, activeRowIdx),
                    updatedRow,
                    activeRowIdx
                );
            });
        }
    };

    const handleMouseUp = (e) => {
        if (isMoveMode(mode)) {
            setMoveablePointIndex(-1);
            setMoveableArrowPointIndex(-1);
        }
    };

    const handleMouseDown = (e) => {
        const point = getPointCoordinatesRelativeToCanvas(
            canvasRef.current,
            e.clientX,
            e.clientY
        );

        if (isDrawingMode(mode)) {
            addDot(point);
            return;
        }

        const pointIndex = findIndexOfApproximatePoint(
            rows[activeRowIdx]?.dots || [],
            point.x,
            point.y
        );
        const arrowPointIndex = findIndexOfApproximatePoint(
            rows[activeRowIdx]?.arrow?.dots || [],
            point.x,
            point.y
        );
        if (pointIndex === -1 && arrowPointIndex === -1) return;

        if (isClearMode(mode)) {
            clearDot(pointIndex, arrowPointIndex);
            return;
        }

        if (isMoveMode(mode)) {
            setMoveablePointIndex(pointIndex);
            setMoveableArrowPointIndex(arrowPointIndex);
            return;
        }
    };

    const clearDot = (pointIndex, arrowPointIndex) => {
        const updatedRow = { ...rows[activeRowIdx] };

        if (pointIndex !== -1) {
            updatedRow.dots = removeItemAtIndex(updatedRow.dots, pointIndex);
        }

        if (arrowPointIndex !== -1) {
            updatedRow.arrow.dots = removeItemAtIndex(
                updatedRow.arrow.dots,
                arrowPointIndex
            );
        }

        setRows((prev) => {
            return insertItemAtIndex(
                removeItemAtIndex(prev, activeRowIdx),
                updatedRow,
                activeRowIdx
            );
        });
    };

    const addDot = (point) => {
        if (isDrawingArrow(mode)) {
            handleDrawingArrow(point);
            return;
        }

        const activeRow = rows[activeRowIdx];
        if (!activeRow) return;
        const updatedRow = {
            ...activeRow,
            dots: [...activeRow.dots, point],
        };

        setRows((prev) =>
            insertItemAtIndex(
                removeItemAtIndex(prev, activeRowIdx),
                updatedRow,
                activeRowIdx
            )
        );
    };

    const handleDrawingArrow = (point) => {
        const activeRow = rows[activeRowIdx];

        if (
            canRowContainsArrow(activeRow) &&
            !doesRowContainArrow(activeRow) &&
            isPointInsideArea(point, activeRow?.dots)
        ) {
            const updatedRow = {
                ...activeRow,
                arrow: {
                    ...activeRow.arrow,
                    dots: [...activeRow.arrow.dots, point],
                },
            };

            setRows((prev) =>
                insertItemAtIndex(
                    removeItemAtIndex(prev, activeRowIdx),
                    updatedRow,
                    activeRowIdx
                )
            );
        }
    };

    const setActiveControl = (type) => {
        if (isDrawingArrow(type) && !canRowContainsArrow(rows[activeRowIdx])) {
            return;
        }

        setMode(type);

        if (isDrawingMode(type)) {
            if (isDrawingArrow(type)) return;
            createNewRow(type);
        }
    };

    const createNewRow = (type) => {
        const newRow = {
            type,
            color: colors[0],
            dots: [],
            label: RowLabels[type],
            icon: RowIcons[type],
            arrow: {
                exists: type.includes("arrow"),
                dots: type.includes("arrow") ? [] : null,
            },
        };
        setRows((prev) => [...prev, newRow]);
        setActiveRowIdx(rows.length);
    };

    const changeRowColor = (event, rowIndex) => {
        const updatedRow = rows[rowIndex];
        updatedRow.color = event.target.value;

        setRows((prev) =>
            insertItemAtIndex(
                removeItemAtIndex(prev, rowIndex),
                updatedRow,
                rowIndex
            )
        );
    };

    const saveFieldValue = () => {
        form.setFieldValue(
            props.code,
            mapRowsToServerFormat(rows, scalingFactor.current)
        );
    };

    const deleteRow = (index) => {
        setRows((prev) => removeItemAtIndex(prev, index));
        removeErrorAtIndex(index);
    };

    const setActiveRow = (index, type) => {
        setActiveRowIdx(index);
        setMode(type);
    };

    useEffect(repaintCanvas, [rows]);
    useEffect(saveFieldValue, [rows]);
    useEffect(
        () => checkFigures(rows[activeRowIdx], activeRowIdx),
        [activeRowIdx, checkFigures, rows]
    );

    return (
        <div>
            <Field
                name={props.code}
                validate={(value) => {
                    return !(
                        errors.length === 0 ||
                        errors.every((arr) => arr.length === 0)
                    );
                }}
            >
                {({ field, form, meta }) => (
                    <div className={"canvasGroup"}>
                        <label htmlFor={props.code}>{props.name}</label>
                        <canvas
                            id={props.code}
                            width={canvasWidth}
                            ref={canvasRef}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseUp}
                            className={mode}
                        ></canvas>
                        <div
                            className="canvasMenu"
                            style={{
                                maxHeight: canvasHeight + "px",
                            }}
                        >
                            <div className="panel">
                                {mapCanvasControlsToCanvasConfig(
                                    props.controls
                                ).map(
                                    ({
                                        control,
                                        imgSrc,
                                        activeImgSrc,
                                        title,
                                    }) => (
                                        <div
                                            key={control}
                                            className="panelItem"
                                            onClick={() =>
                                                setActiveControl(control)
                                            }
                                            title={title}
                                        >
                                            <img
                                                src={
                                                    mode === control
                                                        ? activeImgSrc || imgSrc
                                                        : imgSrc
                                                }
                                                alt={title}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                            <div className="items">
                                {rows.map((row, index) => {
                                    return (
                                        <div
                                            className={`item ${
                                                index === activeRowIdx &&
                                                "active"
                                            }`}
                                            key={index}
                                            onClick={() =>
                                                setActiveRow(index, row.type)
                                            }
                                        >
                                            <div className="itemLabel">
                                                <img
                                                    src={row.icon}
                                                    alt={row.label}
                                                />
                                                {row.label} {index + 1}
                                            </div>
                                            <div
                                                className="color"
                                                style={{
                                                    background: row.color,
                                                }}
                                            >
                                                <input
                                                    onChange={(e) =>
                                                        changeRowColor(e, index)
                                                    }
                                                    value={row.color}
                                                    type="color"
                                                />
                                            </div>
                                            <div
                                                className="deleteElement"
                                                onClick={() => deleteRow(index)}
                                            >
                                                &times;
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="errorWrap">
                            {getFlatArrayOfErrors().map((item, index) => {
                                return (
                                    <div className="error" key={index}>
                                        {item}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </Field>
        </div>
    );
}

export default CanvasField;
