import React, {Component} from 'react';
import {store} from "../../../redux/initialStore";
import {findObject} from "../../../assets/functions/search";

class Diagnostic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            diagnostic: {}
        }
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            const neuroboxesList = store.getState().configuration.neuroboxes.list;
            const diagnostic = findObject(neuroboxesList, "id", this.props.neurobox.id).diagnostic;

            this.setState({
                diagnostic: diagnostic
            })
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        let { neurobox } = this.props;
        let { diagnostic } = this.state;
        let camera = diagnostic?.CAM;
        let cpu = diagnostic?.CPU;
        let internet = diagnostic?.Internet;
        let storage = diagnostic?.Storage;

        return (
            <div className={"neurobox-diagnostic"}>
                <h1 className={"neurobox-header"}>{neurobox?.name || "Неизвестный Нейробокс"}</h1>
                <h2>Камера:</h2>
                <div>
                    Статус: {camera?.Status === "on" ? "Включена" : "Выключена"}
                </div>

                <h2>Процессор:</h2>
                <div>
                    Загруженность процессора 1: {cpu?.CPU1 || 0}%<br/>
                    Загруженность процессора 2: {cpu?.CPU2 || 0}%<br/>
                    Загруженность процессора 3: {cpu?.CPU3 || 0}%<br/>
                    Загруженность процессора 4: {cpu?.CPU4 || 0}%<br/>
                    Средняя загруженность: {cpu?.AVG || 0}%<br/>
                    Температура: {cpu?.CPU_TEMP || 0}°C
                </div>

                <h2>Скорость интернета:</h2>
                <div>
                    Скорость загрузки: {internet?.Download || 0} bit/сек<br/>
                    Джиттер: {internet?.Jitter || 0} сек<br/>
                    Скорость отдачи: {internet?.Upload || 0} bit/сек
                </div>

                <h2>Память:</h2>
                <div>
                    Диск: {storage?.LOCAL?.Used || 0} Гб/{storage?.LOCAL?.Total || 0} Гб ({storage?.LOCAL?.Free || 0} Гб свободно)<br/>
                    RAM: {storage?.RAM?.Used || 0} Мб/{storage?.RAM?.Total || 0} Мб ({storage?.RAM?.Free || 0} Мб свободно)<br/>
                    SWAP: {storage?.SWAP?.Used || 0} Мб/{storage?.SWAP?.Total || 0} Мб ({storage?.SWAP?.Free || 0} Мб свободно)
                </div>
            </div>
        )
    }
}

export default Diagnostic