import {UPDATE_WIDGET_EVENTS_LIST} from "../../../actionsTypes";
import {store} from "../../../initialStore";
import {EVENTS_WIDGET_PORTION_SIZE} from "../../../../constants/events";
import {getAndUpdateEventsList} from "../../common/events";

export function updateWidgetEventsList(list) {
    return {
        type: UPDATE_WIDGET_EVENTS_LIST,
        value: list
    }
}

export function loadWidgetEventsList() {
    let dateRange = store.getState().dashboard.settings.dateRange;
    let [ dateStart, dateEnd ] = dateRange;

    let params = {
        limit: EVENTS_WIDGET_PORTION_SIZE,
        custom: { dateStart, dateEnd },
        orderBy: {'time': 'DESC'}
    }

    return getAndUpdateEventsList(params, updateWidgetEventsList);
}