import axios from "axios";
import { ATTACH_CAMERA, CAMERA, CAMERAS } from "../../URLs";

export function getCamerasList() {
    return axios.get(CAMERAS, { withCredentials: true });
}

export function attachCamera(...ids) {
    return axios.patch(ATTACH_CAMERA(...ids), { withCredentials: true });
}

export function createCamera(camera) {
    return axios.post(CAMERAS, camera, { withCredentials: true });
}

export function deleteCamera(cameraId) {
    return axios.delete(CAMERA(cameraId), { withCredentials: true });
}
