import React from "react";
import { Icon } from "@iconify/react";
import plusCircleOutline from "@iconify-icons/mdi/plus-circle-outline";
import styles from "./styles/AddCameraButton.module.css";

export const AddCameraButton = ({ addCameraHanlder }) => {
    return (
        <button
            className={styles.button}
            onClick={addCameraHanlder}
            title="Добавить камеру"
        >
            <Icon className={styles.icon} icon={plusCircleOutline} />
        </button>
    );
};
