import * as types from "../actionsTypes";
import {checkUser} from "../../assets/axios/requests/user";
import {handleError} from "../../assets/functions/errors";
import {USER_INFO} from "../../constants/errors";
import {updateModalState} from "./common/modal";

function updateUserInfo(info) {
    return {
        type: types.UPDATE_USER_INFO,
        value: info
    };
}

export function loadUserInfo(redirect = true) {
    return async dispatch => {
        try {
            let response = await checkUser();
            dispatch( updateUserInfo(response.data) );
        } catch (error) {
            handleError(error, USER_INFO, (...args) => dispatch(updateModalState(...args)), redirect);
        }

    }
}