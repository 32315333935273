import {UPDATE_EVENT_DETAILS} from "../../actionsTypes";
import {getEventDetails} from "../../../assets/axios/requests/events";
import {handleError} from "../../../assets/functions/errors";
import {EVENT_DETAILS} from "../../../constants/errors";
import {updateModalState} from "../common/modal";

export function updateEventDetails(details) {
    return {
        type: UPDATE_EVENT_DETAILS,
        value: details
    }
}

export function loadEventDetails(eventId) {
    return async dispatch => {
        try {
            let response = await getEventDetails(eventId);
            dispatch( updateEventDetails(response.data) );
        } catch (error) {
            handleError(error, EVENT_DETAILS, (...args) => dispatch(updateModalState(...args)));
        }
    };
}