import { getTransparentColor } from "./colors";

export function drawFigure(canvas, row) {
    switch (row.type) {
        case "line":
            drawLine(canvas, row.dots, row.color);
            break;
        case "area":
            drawArea(canvas, row.dots, row.color);
            break;
        case "arrow_area":
        case "speed_arrow_area":
            drawArea(canvas, row.dots, row.color);
            drawArrow(canvas, row.arrow.dots, row.arrow.color);
            break;
        default:
            return;
    }
}

export function drawLine(canvas, dots, color) {
    if (!canvas || dots.length === 0) return;
    const context = canvas.getContext("2d");

    context.beginPath();
    context.lineWidth = 1;
    context.moveTo(dots[0].x, dots[0].y);
    for (let i = 0; i < dots.length; i++) {
        context.lineTo(dots[i].x, dots[i].y);
    }
    context.strokeStyle = color;
    context.lineWidth = 1;
    context.stroke();
    context.closePath();
}

export function drawArea(canvas, dots, color) {
    if (!canvas || dots.length === 0) return;
    const context = canvas.getContext("2d");

    context.beginPath();
    context.lineWidth = 1;
    context.moveTo(dots[0].x, dots[0].y);
    for (let i = 0; i < dots.length; i++) {
        context.lineTo(dots[i].x, dots[i].y);
    }
    context.lineTo(dots[0].x, dots[0].y);
    context.strokeStyle = color;
    context.fillStyle = getTransparentColor(color, 0.3);
    context.lineWidth = 1;
    context.fill();
    context.stroke();
    context.closePath();
}

export function drawArrow(canvas, dots, color) {
    if (!canvas || !dots || dots.length === 0) return;
    const context = canvas.getContext("2d");
    context.beginPath();
    for (let i = 0; i < dots.length; i++) {
        context.beginPath();
        context.lineWidth = 1;
        context.fillStyle = color;
        context.arc(dots[i].x, dots[i].y, 3, 0, 2 * Math.PI);
        context.fill();
    }
    context.closePath();
    const arrowLen = 15;
    const dx = dots[1]?.x - dots[0]?.x;
    const dy = dots[1]?.y - dots[0]?.y;
    const angle = Math.atan2(dy, dx);
    context.beginPath();

    context.lineWidth = 1;
    context.moveTo(dots[0]?.x, dots[0]?.y);
    context.lineTo(dots[1]?.x, dots[1]?.y);
    context.stroke();
    context.moveTo(dots[1]?.x, dots[1]?.y);
    context.lineTo(
        dots[1]?.x - arrowLen * Math.cos(angle - Math.PI / 6),
        dots[1]?.y - arrowLen * Math.sin(angle - Math.PI / 6)
    );
    context.stroke();
    context.moveTo(dots[1]?.x, dots[1]?.y);
    context.lineTo(
        dots[1]?.x - arrowLen * Math.cos(angle + Math.PI / 6),
        dots[1]?.y - arrowLen * Math.sin(angle + Math.PI / 6)
    );
    context.stroke();
    context.closePath();
}

export function clearCanvas(canvas) {
    if (!canvas) return;
    const canvasCtx = canvas.getContext("2d");
    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
}

export function drawImage(canvas, backgroundImage) {
    if (!canvas) return;
    const canvasCtx = canvas.getContext("2d");
    canvasCtx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
}

export function drawDots(canvas, dots, color) {
    if (!canvas) return;
    const canvasCtx = canvas.getContext("2d");
    canvasCtx.beginPath();
    for (let i = 0; i < dots.length; i++) {
        canvasCtx.beginPath();
        canvasCtx.lineWidth = 1;
        canvasCtx.fillStyle = color;
        canvasCtx.arc(dots[i].x, dots[i].y, 5, 0, 2 * Math.PI);
        canvasCtx.fill();
    }
    canvasCtx.closePath();
}
