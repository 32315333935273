import {UPDATE_DETECTORS_LIST} from "../../actionsTypes";
import {getDetectorsList} from "../../../assets/axios/requests/configuration/detectors";
import {handleError} from "../../../assets/functions/errors";
import {DETECTORS_LIST} from "../../../constants/errors";
import {updateModalState} from "./modal";

export function updateDetectorsList(list) {
    return {
        type: UPDATE_DETECTORS_LIST,
        value: list
    }
}

export function loadDetectorsList() {
    return async dispatch => {
        try {
            let response = await getDetectorsList();
            dispatch( updateDetectorsList(response.data) );
        } catch (error) {
            handleError(error, DETECTORS_LIST, (...args) => dispatch(updateModalState(...args)));
        }
    }
}