import React, {Component} from 'react';
import {connect} from "react-redux";
import {updateMenuState} from "../../redux/actions/menu";

class Settings extends Component {
    componentDidMount() {
        this.props.updateMenuState();
    }

    render() {
        return (
            <div>Hello, this is Settings in neuro_cloud</div>
        )
    }
}

export default connect(state => state, { updateMenuState }) (Settings)