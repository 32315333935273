import React, {Component} from 'react';

class Row extends Component {
    render() {
        let value = this.props.value;
        if(this.props.type === 'object'){
            value = "(";
            for (const [key, val] of Object.entries(this.props.value)) {
                value += key + ": " + val + ";"
            }
            value += ")";
        }
        return (
            <div className={"row"}>
                <div className={"label"}>{ this.props.label }:</div>
                <div className={"value"}>{ value }</div>
            </div>
        )
    }
}

export default Row