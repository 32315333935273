import {UPDATE_DETECTOR_DETAILS} from "../../actionsTypes";
import {getDetectorDetails} from "../../../assets/axios/requests/shop";
import {handleError} from "../../../assets/functions/errors";
import {DETECTOR_DETAILS} from "../../../constants/errors";
import {updateModalState} from "../common/modal";

export function updateDetectorDetails(details) {
    return {
        type: UPDATE_DETECTOR_DETAILS,
        value: details
    }
}

export function loadDetectorDetails(detectorId) {
    return async dispatch => {
        try {
            let response = await getDetectorDetails(detectorId);
            dispatch( updateDetectorDetails(response.data) );
        } catch (error) {
            handleError(error, DETECTOR_DETAILS, (...args) => dispatch(updateModalState(...args)));
        }
    };
}