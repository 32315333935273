import React from "react";
import { Formik, Form, Field } from "formik";
import {
    validateCameraAddress,
    validateCameraName,
} from "../../../assets/functions/validators";
import styles from "./styles/AddCameraForm.module.css";

const CameraInputField = ({ errors, touched, name, label, validate }) => {
    return (
        <div className={styles.inputGroup}>
            <label htmlFor={`camera-${name}`} className={styles.label}>
                {label}
            </label>
            <Field
                type="text"
                id={`camera-${name}`}
                name={name}
                validate={validate}
                className={styles.field}
            />
            {errors[name] && touched[name] && (
                <div className={styles.error}>{errors[name]}</div>
            )}
        </div>
    );
};

const inititalValues = {
    name: "",
    address: "",
    type: "web",
};

export const AddCameraForm = ({ closeModal, createCamera }) => {
    const addNewCamera = (values) => {
        createCamera(values);
        closeModal();
    };

    return (
        <div>
            <h1>Добавление камеры</h1>
            <Formik initialValues={inititalValues} onSubmit={addNewCamera}>
                {({ errors, touched, isValidating }) => (
                    <Form className={styles.form}>
                        <CameraInputField
                            errors={errors}
                            touched={touched}
                            name="name"
                            label="Название камеры"
                            validate={validateCameraName}
                        />

                        <CameraInputField
                            errors={errors}
                            touched={touched}
                            name="address"
                            label="Адрес камеры"
                            validate={validateCameraAddress}
                        />

                        <div className={styles.inputGroup}>
                            <label
                                htmlFor="camera-type"
                                className={styles.label}
                            >
                                Тип камеры
                            </label>
                            <Field
                                id="camera-type"
                                name="type"
                                as="select"
                                className={styles.select}
                            >
                                <option value="web">Web</option>
                                <option value="ip">IP</option>
                            </Field>
                        </div>

                        <button type="submit">Добавить камеру</button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
