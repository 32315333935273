import React, {Component} from 'react';
import List from "../../../common/events/List";
import {connect} from "react-redux";
import {loadWidgetEventsList} from "../../../../redux/actions/dashboard/widgets/eventsList";
import Item from "../../../common/events/Item";

class Events extends Component {
    componentDidMount() {
        this.timer = setInterval(() => this.props.loadWidgetEventsList(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        return (
            <List list={this.props.list}>
                { this.props.list.map(event => <Item key={event.id} event={event} />) }
            </List>
        )
    }
}

export default connect(
    state => state.dashboard.widgets.events,
    { loadWidgetEventsList }
) (Events);