export function checkShapeForConvexity(dots) {
    return isConvexShape(dots);
}

export function checkLineForSelfIntersection(dots) {
    for (let i = 0; i < dots.length; i++) {
        for (let j = 0; j < dots.length; j++) {
            const isIntersected = isLinesIntersected(
                dots[i]?.x,
                dots[i]?.y,
                dots[i + 1]?.x,
                dots[i + 1]?.y,
                dots[j]?.x,
                dots[j]?.y,
                dots[j + 1]?.x,
                dots[j + 1]?.y
            );

            if (isIntersected) {
                return true;
            }
        }
    }

    return false;
}

export function isLinesIntersected(x1, y1, x2, y2, x3, y3, x4, y4) {
    const a_dx = x2 - x1;
    const a_dy = y2 - y1;
    const b_dx = x4 - x3;
    const b_dy = y4 - y3;
    const s =
        (-a_dy * (x1 - x3) + a_dx * (y1 - y3)) / (-b_dx * a_dy + a_dx * b_dy);
    const t =
        (+b_dx * (y1 - y3) - b_dy * (x1 - x3)) / (-b_dx * a_dy + a_dx * b_dy);
    const array = [x1, y1, x2, y2, x3, y3, x4, y4];

    if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
        return !(
            array.includes(x1 + t * a_dx) && array.includes(y1 + t * a_dy)
        );
    }

    return false;
}
export function isConvexShape(dots) {
    if (dots.length < 2) return false;

    let result = [];
    for (let i = 0; i < dots.length; i++) {
        if (!dots[i + 2]?.x && !dots[i + 1]?.x) {
            let pointAB = {
                x: dots[i]?.x - dots[0]?.x,
                y: dots[i]?.y - dots[0]?.y,
            };
            let pointBC = {
                x: dots[0]?.x - dots[1]?.x,
                y: dots[0]?.y - dots[1]?.y,
            };
            let product = pointAB.x * pointBC.y - pointAB.y * pointBC.x;
            result.push(product);
        } else if (!dots[i + 2]?.x) {
            let pointAB = {
                x: dots[i]?.x - dots[i + 1]?.x,
                y: dots[i]?.y - dots[i + 1]?.y,
            };
            let pointBC = {
                x: dots[i + 1]?.x - dots[0]?.x,
                y: dots[i + 1]?.y - dots[0]?.y,
            };
            let product = pointAB.x * pointBC.y - pointAB.y * pointBC.x;
            result.push(product);
        } else {
            let pointAB = {
                x: dots[i]?.x - dots[i + 1]?.x,
                y: dots[i]?.y - dots[i + 1]?.y,
            };
            let pointBC = {
                x: dots[i + 1]?.x - dots[i + 2]?.x,
                y: dots[i + 1]?.y - dots[i + 2]?.y,
            };
            let product = pointAB.x * pointBC.y - pointAB.y * pointBC.x;
            result.push(product);
        }
    }
    const plus = (element) => element >= 0;
    const minus = (element) => element <= 0;
    return result.some(plus) !== result.some(minus);
}

export function isPointInsideArea(point, dots = []) {
    let dx = [];
    let dy = [];
    for (let i = 0; i < dots.length; i++) {
        dx.push(dots[i].x);
        dy.push(dots[i].y);
    }
    let npol = dx.length;
    let j = npol - 1;
    let c = 0;
    for (let i = 0; i < npol; i++) {
        if (
            ((dy[i] <= point.y && point.y < dy[j]) ||
                (dy[j] <= point.y && point.y < dy[i])) &&
            point.x >
                ((dx[j] - dx[i]) * (point.y - dy[i])) / (dy[j] - dy[i]) + dx[i]
        ) {
            c = !c;
        }
        j = i;
    }
    return Boolean(c);
}
