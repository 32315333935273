export function findObject(array, prop, value) {
    let obj = {};

    array.forEach(item => {
        if (item[prop] === value) {
            obj = item;
        }
    });

    return obj;
}