import React, { Component } from "react";
import Block from "../../../wrappers/Block";
import CameraItem from "./cameraItem";
import { connect } from "react-redux";
import { AddCameraForm } from "./AddCameraForm";
import { AddCameraButton } from "./AddCameraButton";
import {
    loadCamerasList,
    createCamera,
} from "../../../redux/actions/configuration/cameras";
import { updateModalState } from "../../../redux/actions/common/modal";

class Cameras extends Component {
    constructor(props) {
        super(props);
        this.startObservation = this.startObservation.bind(this);
        this.stopObservation = this.stopObservation.bind(this);
    }

    componentDidMount() {
        this.startObservation();
    }

    componentWillUnmount() {
        this.stopObservation();
    }

    startObservation() {
        this.timer = setInterval(() => this.props.loadCamerasList(), 1000);
    }

    stopObservation() {
        clearInterval(this.timer);
    }

    render() {
        let cameras = this.props.list;

        const button = (
            <AddCameraButton
                addCameraHanlder={() =>
                    this.props.updateModalState(
                        true,
                        <AddCameraForm
                            closeModal={() =>
                                this.props.updateModalState(false)
                            }
                            createCamera={this.props.createCamera}
                        />
                    )
                }
            />
        );

        return (
            <Block header={"Камеры"} button={button}>
                <div className="cameras container">
                    {cameras.map((camera) => (
                        <CameraItem
                            key={camera.id}
                            camera={camera}
                            onDragStart={this.stopObservation}
                            onDragEnd={this.startObservation}
                        />
                    ))}
                </div>
            </Block>
        );
    }
}

export default connect((state) => state.configuration.cameras, {
    loadCamerasList,
    updateModalState,
    createCamera,
})(Cameras);
