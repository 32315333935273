import React, {Component} from 'react';
import Menu from "./Menu";
import Icon from "@iconify/react";
import chevronDoubleLeft from '@iconify/icons-mdi/chevron-double-left';
import {toggleMenu} from "../../../assets/functions/menu";
import {getCookie, setCookie} from "../../../assets/functions/cookie";

class LeftSidebar extends Component {
    constructor(props) {
        super(props);

        let isOpenMenu = getCookie('isOpenMenu');
        this.state = {
            openMenu:  isOpenMenu !== undefined ? JSON.parse(isOpenMenu) : true
        };

        this.toggleMenuState = this.toggleMenuState.bind(this);
    }

    componentDidMount() {
        toggleMenu(this.state.openMenu);
    }

    toggleMenuState() {
        this.setState(
            state => {
                setCookie('isOpenMenu', !state.openMenu, { 'max-age': 60*60*24*30*12*100 })
                return { openMenu: !state.openMenu }
            },
            () => toggleMenu(this.state.openMenu)
        );
    }

    render() {
        return (
            <div className={"left-sidebar"}>
                <div className={"show-hide-arrow"}
                     onClick={ this.toggleMenuState }>
                    <Icon icon={chevronDoubleLeft} />
                </div>

                <Menu/>
            </div>
        )
    }
}

export default LeftSidebar