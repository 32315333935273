import {INITIAL_STORE_STATE} from "../initialStore";
import menuReducer from "./menu";
import authTabletReducer from "./auth";
import userReducer from "./user";
import configurationReducer from "./configuration";
import dashboardReducer from "./dashboard";
import {eventsReducer} from "./events";
import {shopReducer} from "./shop";
import modalReducer from "./modal";

export default function mainReducer(state = INITIAL_STORE_STATE, action) {
    return {
        user: userReducer(state.user, action),
        authTabletView: authTabletReducer(state.authTabletView, action),
        menuItems: menuReducer(state.menuItems, action),
        modal: modalReducer(state.modal, action),
        dashboard: dashboardReducer(state.dashboard, action),
        events: eventsReducer(state.events, action),
        configuration: configurationReducer(state.configuration, action),
        shop: shopReducer(state.shop, action)
    }
}