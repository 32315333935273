import React, {Component} from 'react';
import List from "../../common/detectors/List";
import DraggableItem from "./DraggableItem";

class Detectors extends Component {
    render() {

        return (
            <List>
                {
                    detector => <DraggableItem detector={detector} key={detector.id} withToolbar />
                }
            </List>
        )
    }
}

export default Detectors;