import {INITIAL_STORE_STATE} from "../initialStore";
import * as types from "../actionsTypes";

export default function dashboardReducer(state = INITIAL_STORE_STATE.dashboard, action) {
    switch (action.type) {
        case types.UPDATE_DASHBOARD_DATE_RANGE:
            return Object.assign({}, state, {
                settings: {
                    dateRange: action.value
                }
            });
        case types.UPDATE_WIDGET_EVENTS_LIST:
            return Object.assign({}, state, {
                widgets: {
                    events: {
                        list: action.value
                    }
                }
            });
        default:
            return state;
    }
}