import React, {Component} from 'react';
import Header from "./Header";
import Form from "./form";

class DetectorSettings extends Component {
    render() {
        let { detector } = this.props;

        return (
            <div className={"detector-settings"}>
                <Header detector={detector}/>
                <Form {...this.props}/>
            </div>
        )
    }
}

export default DetectorSettings