import React from "react";
import { Icon } from "@iconify/react";
import windowClose from "@iconify-icons/mdi/window-close";
import styles from "./styles/DeleteButton.module.css";

export const DeleteButton = ({ handleDelete, title }) => {
    return (
        <button
            title={title}
            className={styles.deleteButton}
            onClick={handleDelete}
        >
            <Icon className={styles.closeIcon} icon={windowClose} />
        </button>
    );
};
