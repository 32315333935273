import axios from 'axios';
import {ATTACH_DETECTOR, DETECTOR_PARAMETERS, DETECTORS} from "../../URLs";

export function getDetectorsList() {
    return axios.get(DETECTORS, { withCredentials: true });
}

export function attachDetector(cameraId, detectorId, data) {
    return axios.patch(ATTACH_DETECTOR(cameraId, detectorId), data,{ withCredentials: true});
}

export function getDetectorParameters(id) {
    return axios.get(DETECTOR_PARAMETERS(id), { withCredentials: true });
}

export function saveDetectorParameters(id, values) {
    return axios.post(DETECTOR_PARAMETERS(id), values,{ withCredentials: true });
}

export function clearDetectorParameters(id) {
    return axios.delete(DETECTOR_PARAMETERS(id),{ withCredentials: true });
}