import React, {Component} from 'react';

class Content extends Component {
    render() {
        return (
            <div className={"page-auto-scroll"}>
                <div className={"default-page-indent"}>
                    { this.props.children }
                </div>
            </div>
        )
    }
}

export default Content