export function genArrayOfHexColors(count = 10) {
    const colors = [];
    for (let i = 0; i < count; i++) {
        let color = Math.floor(Math.random() * 10000000 + 1);
        if (colors.indexOf(color) <= 0)
            colors.push("#" + ("000000" + color.toString(16)).slice(-6));
    }
    return colors;
}

export const getTransparentColor = (color, opacity) => {
    const rgb = hexToRgb(color);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity}`;
};

export const hexToRgb = (c) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(c);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
};
