import {ADD_CAMERA, DELETE_CAMERA, UPDATE_CAMERAS_LIST} from "../../actionsTypes";
import {
    createCamera as createCameraReq,
    deleteCamera as deleteCameraReq,
    getCamerasList
} from "../../../assets/axios/requests/configuration/cameras";
import {handleError} from "../../../assets/functions/errors";
import {CAMERAS_LIST, CAMERA_CREATION, CAMERA_DELETING} from "../../../constants/errors";
import {updateModalState} from "../common/modal";

export function updateCamerasList(list) {
    return {
        type: UPDATE_CAMERAS_LIST,
        value: list
    }
}

export function addCamera(camera) {
    return {
        type: ADD_CAMERA,
        value: camera
    }
}

export function removeCamera(camera) {
    return {
        type: DELETE_CAMERA,
        value: camera
    }
}

export function loadCamerasList() {
    return async dispatch => {
        try {
            let response = await getCamerasList();
            dispatch( updateCamerasList(response.data) );
        } catch (error) {
            handleError(error, CAMERAS_LIST, (...args) => dispatch(updateModalState(...args)))
        }
    }
}

export function createCamera(camera) {
    return async dispatch => {
        try {
            let response = await createCameraReq(camera);
            dispatch( addCamera(response.data) );
        } catch (error) {
            handleError(error, CAMERA_CREATION, (...args) => dispatch(updateModalState(...args)))
        }
    }
}

export function deleteCamera(cameraId) {
    return async dispatch => {
        try {
            let response = await deleteCameraReq(cameraId);
            dispatch( removeCamera(response.data) );
        } catch (error) {
            handleError(error, CAMERA_DELETING, (...args) => dispatch(updateModalState(...args)))
        }
    }
}