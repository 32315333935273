import exitIcon from '@iconify/icons-icomoon-free/exit';
import {exitUser} from "../assets/axios/requests/user";

export const USER_MENU_ITEMS = [
    { onCLick: exitUser, title: 'Выход из системы', icon: exitIcon }
];

export const SHOWN_USER_MENU_STYLES = [
    { selector: '.top-sidebar .user-info', styles: { background: 'var(--dark-background-color)' } },
    { selector: '.top-sidebar .user-info > svg', styles: { transform: 'rotate(180deg)' } },
    { selector: '.top-sidebar .user-info .user-menu', styles: { height: `${ USER_MENU_ITEMS.length * 40 + 1 }px` } }
];

export const HIDDEN_USER_MENU_STYLES = [
    { selector: '.top-sidebar .user-info', styles: { background: 'transparent' } },
    { selector: '.top-sidebar .user-info > svg', styles: { transform: 'rotate(0deg)' } },
    { selector: '.top-sidebar .user-info .user-menu', styles: { height: '0' } }
];