export function findIndexOfApproximatePoint(dots = [], clientX, clientY) {
    for (let i = 0; i < dots.length; i++) {
        const deltaX = dots[i].x - clientX;
        const deltaY = dots[i].y - clientY;
        const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
        if (distance < 10) {
            return i;
        }
    }
    return -1;
}

export function removeItemAtIndex(array, index) {
    return [...array.slice(0, index), ...array.slice(index + 1)];
}

export function insertItemAtIndex(array, item, index) {
    return [...array.slice(0, index), item, ...array.slice(index)];
}

export function getPointCoordinatesRelativeToCanvas(canvas, clientX, clientY) {
    const rectangle = canvas.getBoundingClientRect();
    return {
        x: Math.round(clientX - rectangle.left),
        y: Math.round(clientY - rectangle.top),
    };
}

export function scaleDots(dots, scale) {
    return dots.map((dot) => ({
        x: Math.round(dot.x / scale),
        y: Math.round(dot.y / scale),
    }));
}
