import React, {Component} from 'react';
import {connect} from "react-redux";
import DetectorSettings from "../../common/detectorSettings";
import emptyShot from "../../../../assets/img/empty/shot.svg";
import neuroboxIcon from "../../../../assets/img/neurobox.svg";
import emptyDetector from "../../../../assets/img/empty/detector.svg";
import {createImagePath} from "../../../../assets/functions/formatting";
import {updateModalState} from "../../../../redux/actions/common/modal";
import {deleteCamera} from "../../../../redux/actions/configuration/cameras"
import {attachDetector} from "../../../../assets/axios/requests/configuration/detectors";
import { DeleteButton } from '../../common/deleteButton/DeleteButton';

class Content extends Component {
    constructor(props) {
        super(props);

        this.deleteCamera = this.deleteCamera.bind(this);
    }

    deleteCamera() {
        const camera = this.props.camera;
        const isConfirmed = window.confirm(`Удалить камеру ${camera.name}?`);
        if (isConfirmed) {
            this.props.deleteCamera(camera.id);
        }
    }

    render() {
        let { camera } = this.props;
        let type = camera?.type;
        let status = camera?.status;
        let image = createImagePath(camera, emptyShot);
        let neurobox = camera?.neurobox;
        let detector = camera?.detector;
        let detectorIcon = createImagePath(camera?.detector, emptyDetector);
        let cameraAddress = type === "web"
            ? "web-камера"
            : type === "ip"
                ? camera?.address || "Неизвестный адрес"
                : "Неизвестный тип камеры";

        return (
            <div className={`item ${ this.props.isHovered ? "droppable-hovered" : "" }`}>
                <div className={"shot img-container"}
                     title={ camera?.name || "Неизвестная камера" }
                     style={{
                    backgroundImage: `url(${image}), url(${emptyShot})`
                }}>
                    {/*<div className={"update-shot"}>*/}
                    {/*    <img src={updateIcon}*/}
                    {/*         alt={"Обновить кадр"}*/}
                    {/*         title={"Обновить кадр"}/>*/}
                    {/*</div>*/}
                </div>
                <div className={"info"}>
                    <div className={"name"}>{ camera?.name || "Неизвестная камера" }</div>
                    <div className={"address"} title={cameraAddress}>{cameraAddress}</div>
                    <div title={neurobox === null
                        ? ""
                        : neurobox?.ip || "Неизвестный адрес"}>

                        { neurobox === null
                            ? ""
                            : neurobox?.name || "Неизвестный Нейробокс" }

                    </div>
                </div>
                <DeleteButton handleDelete={this.deleteCamera} title="Удалить камеру" />
                {
                    status === null
                        ? null
                        : <div className={"camera-icons"}>
                            {
                                neurobox === null
                                    ? ""
                                    : <div className={`${status ? "online" : "offline"}`}>
                                        <img src={neuroboxIcon} alt={""} title={status
                                            ? "Камера подключена к Нейробоксу"
                                            : "Камера отключена от Нейробокса"}/>
                                    </div>
                            }

                            {
                                detector === null
                                    ? ""
                                    : <>
                                        <div title={detector?.name || "Неизвестный детектор"}
                                             onClick={() => this.props.updateModalState(
                                                 true, <DetectorSettings detector={detector}
                                                                         camera={camera}
                                                                         isConnectionSettings
                                                                         onSubmit={values => attachDetector(camera.id, detector.id, values)}/>)}>
                                            <img src={detectorIcon} alt={""}/>
                                        </div>
                                    </>
                            }
                        </div>
                }
            </div>
        )
    }
}

export default connect(() => ({}), { updateModalState, deleteCamera }) (Content);