import React, {Component} from 'react';
import Block from "../../../wrappers/Block";

class List extends Component {
    render() {
        return (
            <Block header={"Список событий"} className={"list"}>
                <div className={"events container"}>
                    { this.props.children }
                </div>
            </Block>
        )
    }
}

export default List;