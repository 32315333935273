import {CLEAR_EVENTS_TOP_OFFSET, UPDATE_EVENTS_TOP_OFFSET} from "../../actionsTypes";
import {store} from "../../initialStore";
import {
    EVENTS_CURRENT_TIME,
    EVENTS_PORTION_SIZE,
    PORTION_EVENTS_KEY,
    TOP_EVENTS_KEY
} from "../../../constants/events";
import {getAndUpdateEventsList, updateEventsList} from "../common/events";

export function updateEventsTopOffset(offset) {
    return {
        type: UPDATE_EVENTS_TOP_OFFSET,
        value: offset
    }
}

export function clearEventsTopOffset() {
    return {
        type: CLEAR_EVENTS_TOP_OFFSET
    }
}

export function loadMoreEvents(page) {
    let events = store.getState().events;
    let settings = events.settings;
    let dateRange = settings.dateRange;
    let [ dateStart, dateEnd ] = dateRange;
    let portionCount = events.portionCount;
    let params = {
        limit: EVENTS_PORTION_SIZE,
        offset: EVENTS_PORTION_SIZE * portionCount,
        filters: settings.filters,
        custom: { dateStart, dateEnd: dateEnd },
        orderBy: {'time': 'DESC'}
    };

    return getAndUpdateEventsList(params, updateEventsList,
        {
            key: PORTION_EVENTS_KEY,
            responseConcat: 'APPEND',
            data: { page }
        });
}

export function loadTopEvents() {
    let events = store.getState().events;
    let settings = events.settings;
    let dateEnd = settings.dateRange[1];

    let params = {
        offset: events.topOffset,
        filters: settings.filters,
        orderBy: { time: "ASC" },
        custom: { dateStart: EVENTS_CURRENT_TIME, dateEnd }
    }

    return getAndUpdateEventsList(params, updateEventsList,
        {
            key: TOP_EVENTS_KEY,
            responseConcat: 'PREPEND'
        });
}