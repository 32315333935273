export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_AUTH_TABLET_VIEW = 'UPDATE_TABLET_VIEW';

export const UPDATE_MENU_STATE = 'UPDATE_MENU_STATE';

export const UPDATE_DASHBOARD_DATE_RANGE = 'UPDATE_DASHBOARD_DATE_RANGE';
export const UPDATE_WIDGET_EVENTS_LIST = 'UPDATE_WIDGET_EVENTS_LIST';

export const UPDATE_EVENTS_LIST = 'UPDATE_EVENTS_LIST';
export const UPDATE_EVENT_DETAILS = 'UPDATE_EVENT_DETAILS';
export const UPDATE_EVENTS_DATE_RANGE = 'UPDATE_EVENTS_DATE_RANGE';
export const UPDATE_EVENTS_PORTION_COUNT = 'UPDATE_EVENTS_PORTION_COUNT';
export const UPDATE_EVENTS_TOP_OFFSET = 'UPDATE_EVENTS_TOP_OFFSET';
export const CLEAR_EVENTS_TOP_OFFSET = 'CLEAR_EVENTS_TOP_OFFSET';

export const UPDATE_EVENTS_FILTERS_NEUROBOX = 'UPDATE_EVENTS_FILTERS_NEUROBOX';
export const UPDATE_EVENTS_FILTERS_TYPE = 'UPDATE_EVENTS_FILTERS_TYPE';

export const UPDATE_NEUROBOXES_LIST = 'UPDATE_NEUROBOXES_LIST';
export const UPDATE_DETECTORS_LIST = 'UPDATE_DETECTORS_LIST';
export const UPDATE_CAMERAS_LIST = 'UPDATE_CAMERAS_LIST';
export const ADD_CAMERA = 'CREATE_CAMERA';
export const DELETE_CAMERA = 'DELETE_CAMERA';

export const UPDATE_DETECTOR_DETAILS = 'UPDATE_DETECTOR_DETAILS';

export const UPDATE_MODAL_STATE = 'UPDATE_MODAL_STATE';

export const DELETE_NEUROBOX = "DELETE_NEUROBOX";
