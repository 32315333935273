import React, {Component} from 'react';
import Loader from "../components/common/Loader";
import InfiniteScroll from "react-infinite-scroller";

class LazyScroll extends Component {
    render() {
        return (
            <InfiniteScroll
                pageStart={0}
                initialLoad={true}
                loadMore={this.props.loadMore}
                hasMore={this.props.hasMore}
                loader={<Loader key={0}/>}
                useWindow={false}
            >
                {this.props.children}
            </InfiniteScroll>
        )
    }
}

export default LazyScroll