import axios from 'axios';
import {SESSION} from "../URLs";

export function authUser(userData) {
    return (
        axios.post(SESSION, userData, { withCredentials: true })
            .then(() => window.location.href = '/dashboard')
    );
}

export function checkUser() {
    return axios.get(SESSION, { withCredentials: true });
}

export function exitUser() {
    axios.delete(SESSION, { withCredentials: true })
        .then(() => window.location.reload());
}