import React, {Component} from 'react';
import Item from "../../common/detectors/Item";
import {connect} from "react-redux";
import {loadDetectorDetails} from "../../../redux/actions/shop/detectors";

class ClickableItem extends Component {
    render() {
        let { detector } = this.props;
        return (
            <Item detector={detector}
                  className={this.props.selected === detector.id ? "selected" : ""}
                  onClick={() => this.props.loadDetectorDetails(detector.id)} />
        )
    }
}
export default connect(state => state.shop.detectors,
    { loadDetectorDetails }) (ClickableItem);