import React, {Component} from 'react';
import {connect} from "react-redux";
import Diagnostic from "./Diagnostic";
import {PING_TIME} from "../../../constants/common";
import {updateModalState} from "../../../redux/actions/common/modal";
import {deleteNeurobox} from "../../../redux/actions/configuration/neuroboxes";
import {DeleteButton} from '../../configuration/common/deleteButton/DeleteButton';
import {formatNeuroboxStatus, formatNeuroboxStatusIcon} from "../../../assets/functions/formatting";

class Item extends Component {
    constructor(props) {
        super(props);

        this.deleteNeurobox = this.deleteNeurobox.bind(this);
    }

    deleteNeurobox(event) {
        event.stopPropagation();
        const neurobox = this.props.neurobox;
        const isConfirmed = window.confirm(`Удалить нейробокс ${neurobox.name}?`);
        if (isConfirmed) {
            this.props.deleteNeurobox(neurobox.id);
        }
    }

    render() {
        let { neurobox } = this.props;
        let pingTime = (new Date() - new Date(neurobox.lastPing)) / 1000;
        let isOnline =  pingTime <= PING_TIME;
        let camera = neurobox?.camera;
        let cameraType = neurobox?.camera?.type;

        return (
            <>
                <div className={`item ${ this.props.isHovered ? "droppable-hovered" : "" }`}
                     onClick={() => this.props.updateModalState(true, <Diagnostic neurobox={neurobox} />)}>
                    <div>
                        <div className={ `neuro-state img-container ${isOnline ? "online" : "offline"}` }
                             title={isOnline ? "Нейробокс online" : "Нейробокс offline"}>
                            <img src={ formatNeuroboxStatusIcon(neurobox?.status) }
                                 alt={isOnline ? "online" : "offline"}/>
                        </div>
                        <div className={"status"}>{ formatNeuroboxStatus(neurobox?.status) }</div>
                    </div>
                    <div className={"info"}>
                        <div className={"name"}>{neurobox?.name || "Неизвестный Нейробокс"}</div>
                        <div className={"address"}>{neurobox?.ip || "Неизвестный адрес"}</div>
                        <div title={camera === null
                            ? ""
                            : cameraType === "web"
                                ? "web-камера"
                                : cameraType === "ip"
                                    ? `ip-камера: ${camera?.address || "Неизвестный адрес"}`
                                    : "Неизвестный тип камеры"}>

                            { camera === null
                                ? ""
                                : camera?.name || "Неизвестная камера" }
                        </div>
                    </div>
                    {this.props.editable && <DeleteButton title="Удалить нейробокс" handleDelete={this.deleteNeurobox} />}
                </div>
            </>
        )
    }
}

export default connect(() => ({}), { updateModalState, deleteNeurobox }) (Item);