import React, {Component} from 'react';
import {renderFormField} from "../../../../../../assets/functions/detectorSettings";
import DetectorSettingsType from "../../../../../../wrappers/DetectorSettingsType";

class ConnectionSettings extends Component {
    render() {
        let { settings, camera } = this.props;

        return (
            settings.length
                ? <DetectorSettingsType name={"Настройки связи с детектором"}>
                    {
                        settings.map(param => renderFormField(param, camera))
                    }
                </DetectorSettingsType>
                : null
        )
    }
}

export default ConnectionSettings