export function isDrawingMode(value) {
    return value !== "clear" && value !== "move" && value !== "idle";
}

export function isClearMode(value) {
    return value === "clear";
}

export function isMoveMode(value) {
    return value === "move";
}

export function isDrawingArrow(value) {
    return value === "arrow";
}

export function doesRowContainArrow(row) {
    return Boolean(row?.arrow?.exists && row?.arrow?.dots?.length === 2);
}

export function canRowContainsArrow(row) {
    return Boolean(row?.arrow?.exists);
}
