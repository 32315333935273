import React, {Component} from 'react';
import Item from "../../../common/neuroboxes/Item";
import List from "../../../common/neuroboxes/List";

class Neuroboxes extends Component {
    render() {
        return (
            <List>
                { neurobox => <Item key={neurobox.id} neurobox={neurobox}/> }
            </List>
        )
    }
}

export default Neuroboxes;