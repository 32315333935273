import {SERVER_HOST} from "../../constants/common";

export const SESSION = `${SERVER_HOST}/api/session`;

export const EVENTS = `${SERVER_HOST}/api/event`;
export const EVENT = id => `${SERVER_HOST}/api/event/${id}`;
export const EVENT_TYPES = `${SERVER_HOST}/api/event/type`;

export const NEUROBOXES = `${SERVER_HOST}/api/neurobox`;
export const NEUROBOX = id => `${SERVER_HOST}/api/neurobox/${id}`;
export const CAMERAS = `${SERVER_HOST}/api/camera`;
export const CAMERA = id => `${SERVER_HOST}/api/camera/${id}`;
export const DETECTORS = `${SERVER_HOST}/api/detector`;
export const DETECTOR = id => `${SERVER_HOST}/api/detector/${id}`;
export const DETECTOR_PARAMETERS = id => `${SERVER_HOST}/api/detector/${id}/parameters`;

export const ATTACH_DETECTOR = (cameraId, detectorId) => `${SERVER_HOST}/api/camera/${cameraId}/detector/${detectorId}`;
export const ATTACH_CAMERA = (neuroboxId, cameraId) => `${SERVER_HOST}/api/neurobox/${neuroboxId}/camera/${cameraId}`;
