    import React, {Component} from 'react';
import Item from "../common/events/Item";
import {connect} from "react-redux";
import {loadEventDetails} from "../../redux/actions/events/details";

class ClickableItem extends Component {
    render() {
        let { event } = this.props;
        return (
            <Item event={event}
                  className={this.props.selected === event.id ? "selected" : ""}
                  onClick={() => this.props.loadEventDetails(event.id)} />
        )
    }
}

export default connect(state => state.events,
    { loadEventDetails }) (ClickableItem);