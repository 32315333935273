import React, {Component} from 'react';
import {connect} from "react-redux";
import {updateDashboardDateRange} from "../../../redux/actions/dashboard/settings/dateRange";
import RangeItem from "../../common/page/settings/RangeItem";

class DateRange extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(date) {
        this.props.updateDashboardDateRange(date);
    }

    render() {
        return (
            <RangeItem onChange={this.onChange}
                       value={this.props.settings.dateRange} />
        )
    }
}

export default connect(state => state.dashboard, { updateDashboardDateRange }) (DateRange);