import React, {Component} from 'react';
import Icon from "@iconify/react";
import filterIcon from "@iconify/icons-mdi/filter";
import bxsDownArrow from "@iconify/icons-bx/bxs-down-arrow";
import Parameter from "./Parameter";
import {toggleFiltersMenu} from "../../../../../assets/functions/filtersMenu";

class FiltersItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openMenu: false
        };

        this.toggleMenuState = this.toggleMenuState.bind(this);
    }

    toggleMenuState(parent) {
        this.setState(
            state => ( {openMenu: !state.openMenu} ),
            () => toggleFiltersMenu(this.state.openMenu, parent)
        );
    }

    render() {
        return (
            <div className={"item filters"}
                 onClick={ (event) => {
                     this.toggleMenuState(event.target.closest(".filters"));
                     event.stopPropagation();
                 } }>
                <div className={"name"}>
                    <Icon icon={filterIcon} />
                    <span className={"name-text"}>Фильтры</span>
                    <Icon icon={bxsDownArrow} className={"down-arrow"} />
                </div>

                <div className={"container"}>
                    {
                        this.props.parameters.map((parameter, i) => <Parameter {...parameter} key={i} />)
                    }
                </div>
            </div>
        )
    }
}

export default FiltersItem