import React, {Component} from 'react';
import DotLoader from "react-spinners/DotLoader";

class Loader extends Component {
    render() {
        return (
            <div className={"loader"}>
                <DotLoader
                    size={35}
                    color={"var(--light-text-color)"}
                    loading={true}
                />
            </div>
        )
    }
}

export default Loader