import React, {Component} from 'react';
import {renderFormField} from "../../../../../../assets/functions/detectorSettings";
import DetectorSettingsType from "../../../../../../wrappers/DetectorSettingsType";
import { Icon } from '@iconify/react';
import chevronDown from '@iconify-icons/mdi/chevron-down';

class DefaultSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        }

        this.toggleSettings = this.toggleSettings.bind(this);
    }


    toggleSettings(event) {
        this.setState(state => ({ isOpen: !state.isOpen }));
        event.preventDefault();
    }

    render() {
        let { settings, defaultOnly } = this.props;

        return (
            settings.length
                ? <>
                    {
                        defaultOnly
                            ? null
                            : <div className={"details-arrow"} onClick={this.toggleSettings}>
                                <Icon icon={chevronDown}
                                      style={{
                                          transform: `rotate(${this.state.isOpen ? "180deg" : "0deg"})`
                                      }} />
                            </div>
                    }

                    {
                        this.state.isOpen || defaultOnly
                            ? <DetectorSettingsType name={"Настройки детектора"}>
                                {
                                    settings.map(param => renderFormField(param))
                                }
                            </DetectorSettingsType>
                            : null
                    }
                </>
                : null
        )
    }
}

export default DefaultSettings