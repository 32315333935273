import React from "react";
import CanvasField from "../../components/configuration/common/detectorSettings/form/fields/CanvasField";
import NumberField from "../../components/configuration/common/detectorSettings/form/fields/NumberField";
import RangeField from "../../components/configuration/common/detectorSettings/form/fields/RangeField";
import SelectField from "../../components/configuration/common/detectorSettings/form/fields/SelectField";
import SliderField from "../../components/configuration/common/detectorSettings/form/fields/SliderField";

export function renderFormField(field, camera) {
    switch (field.type) {
        case "number":
            return <NumberField {...field} code={field.key} />;
        case "slider":
            return <SliderField {...field} code={field.key} />;
        case "canvas":
            return <CanvasField {...field} code={field.key} camera={camera} />;
        case "select":
            return <SelectField {...field} code={field.key} />;
        case "range":
            return <RangeField {...field} code={field.key} />;
        default:
            return null;
    }
}

export function splitSettingsAndValues(detectorSettings, formValues) {
    let defaultSettings = [],
        connectionSettings = [];
    let defaultFormValues = {};

    detectorSettings.forEach((item) => {
        if (item.default) {
            defaultSettings.push(item);
            defaultFormValues[item.key] = formValues[item.key];
        } else {
            connectionSettings.push(item);
        }
    });

    return [defaultSettings, connectionSettings, defaultFormValues];
}
