export const EVENT_TYPE_LIST_IN_FILTERS = "EVENT_TYPE_LIST_IN_FILTERS";
export const NEUROBOXES_LIST_IN_FILTERS = "NEUROBOXES_LIST_IN_FILTERS";
export const NEUROBOXES_LIST = "NEUROBOXES_LIST";
export const CAMERAS_LIST = "CAMERAS_LIST";
export const CAMERA_CREATION = "CAMERA_CREATION"
export const CAMERA_DELETING = "CAMERA_DELETING"
export const DETECTORS_LIST = "DETECTORS_LIST";
export const CAMERA_ATTACHMENT = "CAMERA_ATTACHMENT";
export const DETECTOR_ATTACHMENT = "DETECTOR_ATTACHMENT";
export const DETECTOR_PARAMETERS_LIST = "DETECTOR_PARAMETERS_LIST";
export const DETECTOR_PARAMETERS_CLEANING = "DETECTOR_PARAMETERS_CLEANING"
export const EVENTS_LIST = "EVENTS_LIST";
export const EVENT_DETAILS = "EVENT_DETAILS";
export const DETECTOR_DETAILS = "DETECTOR_DETAILS";
export const USER_INFO = "USER_INFO";
export const USER_EXIT = "USER_EXIT";
export const NEUROBOX_DELETING = "NEUROBOX_DELETING";

export const errorsList = {
    [EVENT_TYPE_LIST_IN_FILTERS]: "Не удалось загрузить список типов событий в фильтры.",
    [NEUROBOXES_LIST_IN_FILTERS]: "Не удалось загрузить список Нейробоксов в фильтры.",
    [NEUROBOXES_LIST]: "Не удалось загрузить список Нейробоксов.",
    [CAMERAS_LIST]: "Не удалось загрузить список камер.",
    [DETECTORS_LIST]: "Не удалось загрузить список детекторов.",
    [CAMERA_ATTACHMENT]: "Не удалось прикрепить камеру к Нейробоксу.",
    [CAMERA_CREATION]: "Не удалось добавить новую камеру",
    [CAMERA_DELETING]: "Не удалось удалить камеру",
    [DETECTOR_ATTACHMENT]: "Не удалось сохранить настройки детектора.",
    [DETECTOR_PARAMETERS_LIST]: "Не удалось загрузить параметры детектора.",
    [DETECTOR_PARAMETERS_CLEANING]: "Не удалось сбросить параметры детектора.",
    [EVENTS_LIST]: "Не удалось загрузить список событий.",
    [EVENT_DETAILS]: "Не удалось загрузить информацию о событии.",
    [DETECTOR_DETAILS]: "Не удалось загрузить информацию о детекторе.",
    [USER_INFO]: "Не удалось загрузить информацию о пользователе.",
    [USER_EXIT]: "Не удалось выйти из учетной записи.",
    [NEUROBOX_DELETING]: "Не удалось удалить нейробокс"
};

