import area from "../../../../../../assets/img/detectorSettings/canvasToolbar/area.svg";
import areaSpeed from "../../../../../../assets/img/detectorSettings/canvasToolbar/areaSpeed.svg";
import arrow from "../../../../../../assets/img/detectorSettings/canvasToolbar/arrow.svg";
import arrowActive from "../../../../../../assets/img/detectorSettings/canvasToolbar/arrowActive.svg";
import edit from "../../../../../../assets/img/detectorSettings/canvasToolbar/edit.svg";
import editActive from "../../../../../../assets/img/detectorSettings/canvasToolbar/editActive.svg";
import eraser from "../../../../../../assets/img/detectorSettings/canvasToolbar/eraser.svg";
import eraserActive from "../../../../../../assets/img/detectorSettings/canvasToolbar/eraserActive.svg";
import line from "../../../../../../assets/img/detectorSettings/canvasToolbar/line.svg";

export const RowLabels = {
    line: "Линия",
    area: "Область",
    arrow_area: "Область",
    speed_arrow_area: "Область",
};

export const RowIcons = {
    line: line,
    area: area,
    arrow_area: area,
    speed_arrow_area: areaSpeed,
};

export const Controls = {
    line: {
        control: "line",
        imgSrc: line,
        title: "Линия",
    },
    area: {
        control: "area",
        imgSrc: area,
        title: "Область",
    },
    arrow_area: {
        control: "arrow_area",
        imgSrc: area,
        title: "Область с направлением",
    },
    speed_arrow_area: {
        control: "speed_arrow_area",
        imgSrc: areaSpeed,
        title: "Область с направлением ускорения",
    },
    arrow: {
        control: "arrow",
        imgSrc: arrow,
        activeImgSrc: arrowActive,
        title: "Направление",
    },
    move: {
        control: "move",
        type: "move",
        imgSrc: edit,
        activeImgSrc: editActive,
        title: "Переместить точку",
    },
    clear: {
        control: "clear",
        type: "clear",
        imgSrc: eraser,
        activeImgSrc: eraserActive,
        title: "Удалить точку",
    },
};
