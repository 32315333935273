import React, {Component} from 'react';
import Content from "../../wrappers/Content";
import Detectors from "./detectors";
import Details from "./detectors/Details";
import {connect} from "react-redux";
import {updateMenuState} from "../../redux/actions/menu";

class Shop extends Component {
    componentDidMount() {
        this.props.updateMenuState();
    }

    render() {
        return (
            <>
                <div className={"settings-sidebar"}/>

                <Content>
                    <div className={"block-grid shop"}>
                        <Detectors/>
                        { this.props.detectors.details ? <Details/> : null }
                    </div>
                </Content>
            </>
        )
    }
}

export default connect(state => state.shop,
    { updateMenuState }) (Shop);