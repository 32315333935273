import {INITIAL_STORE_STATE} from "../initialStore";
import * as types from "../actionsTypes";

export default function authTabletReducer(state = INITIAL_STORE_STATE.authTabletView, action) {
    switch (action.type) {
        case types.UPDATE_AUTH_TABLET_VIEW:
            return action.value
        default:
            return state;
    }
}