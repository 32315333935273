import { DELETE_NEUROBOX } from "../../actionsTypes";
import { handleError } from "../../../assets/functions/errors";
import { NEUROBOX_DELETING } from "../../../constants/errors";
import { updateModalState } from "../common/modal";
import { deleteNeurobox as deleteNeuroboxReq } from "../../../assets/axios/requests/configuration/neuroboxes";

export function removeNeurobox(neurobox) {
    return {
        type: DELETE_NEUROBOX,
        value: neurobox,
    };
}

export function deleteNeurobox(neuroboxId) {
    return async (dispatch) => {
        try {
            let response = await deleteNeuroboxReq(neuroboxId);
            dispatch(removeNeurobox(response.data));
        } catch (error) {
            handleError(error, NEUROBOX_DELETING, (...args) =>
                dispatch(updateModalState(...args))
            );
        }
    };
}
