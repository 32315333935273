import React from 'react';
import ReactDOM from 'react-dom';
import Router from "./router";
import * as serviceWorker from './serviceWorker';
import Provider from "react-redux/es/components/Provider";
import {store} from "./redux/initialStore";

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <Router />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
