import React, {Component} from 'react';
import {Draggable, Droppable} from "react-draggable-hoc";
import {CAMERA, DETECTOR} from "../../../../constants/objectTypes";
import Content from "./Content";
import {attachDetector} from "../../../../assets/axios/requests/configuration/detectors";
import {connect} from "react-redux";
import {updateModalState} from "../../../../redux/actions/common/modal";
import DetectorSettings from "../../common/detectorSettings";

class CameraItem extends Component {
    render() {
        let { camera } = this.props;
        camera.objectType = CAMERA;

        return (
            <Draggable dragProps={camera}
                       delay={0}
                       onDragStart={this.props.onDragStart}
                       onDragEnd={this.props.onDragEnd}>
                <Droppable
                    withDragProps={false}
                    onDrop={async ({ dragProps }) => {
                        if (dragProps.objectType === DETECTOR) {
                            try {
                                this.props.updateModalState(
                                    true, <DetectorSettings detector={dragProps}
                                                            camera={camera}
                                                onSubmit={values => attachDetector(camera.id, dragProps.id, values)}/>)
                            } catch (error) {
                                alert(`Не удалось прикрепить детектор к камере (${error})`);
                            }
                        }
                    }}
                >
                    {({isHovered, ref}) => (
                        <div ref={ref} className={'droppable'}>
                            <Content isHovered={isHovered} camera={camera} />
                        </div>
                    )}
                </Droppable>
            </Draggable>
        )
    }
}

export default connect(() => ({}), { updateModalState }) (CameraItem);