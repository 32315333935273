import React, {Component} from 'react';
import {updateMenuState} from "../../redux/actions/menu";
import {connect} from "react-redux";
import Events from "./widgets/events";
import Content from "../../wrappers/Content";
import Neuroboxes from "./widgets/neuroboxes";
import Settings from "./settings";

class Dashboard extends Component {
    componentDidMount() {
        this.props.updateMenuState();
    }

    render() {
        return (
            <>
                <Settings/>

                <Content>
                    <div className={"block-grid"}>
                        <Events/>
                        <Neuroboxes/>
                    </div>
                </Content>
            </>
        );
    }
}

export default connect(() => ({}), { updateMenuState }) (Dashboard);