import React, {Component} from 'react';
import {createImagePath} from "../../../assets/functions/formatting";
import emptyEvent from "../../../assets/img/empty/shot.svg";
import {LOCALE} from "../../../constants/common";
import {connect} from "react-redux";
import {updateModalState} from "../../../redux/actions/common/modal";

class Item extends Component {
    render() {
        let { event } = this.props;
        let image = createImagePath(event, emptyEvent);
        let date = new Date( event?.time );
        return (
            <div className={`item ${this.props.className || ''}`} onClick={this.props.onClick || null}>
                <div className={"shot img-container"}
                     style={{
                         backgroundImage: `url(${image}), url(${emptyEvent})`
                     }}
                     onClick={() => this.props.updateModalState(
                    true, <img src={image} alt={""} className={"full-shot"}/>)}>
                </div>
                <div className={"info"}>
                    <div className={"name"}>{ event?.type?.name || "Неизвестное событие" }</div>
                    <div className={"description"}>{ event?.client?.name || "" }</div>
                </div>
                <div className={"datetime"}>
                    <div className={"time"}>{ date.getTime() ? date.toLocaleTimeString(LOCALE) : '' }</div>
                    <div>{ date.getDate() ? date.toLocaleDateString(LOCALE) : '' }</div>
                </div>
            </div>
        )
    }
}

export default connect(() => ({}), { updateModalState }) (Item);