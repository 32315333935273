import React, {Component} from 'react';
import {connect} from "react-redux";
import {loadUserInfo} from "../../redux/actions/user";

class Auth extends Component {
    componentDidMount() {
        this.props.loadUserInfo(false);
    }

    render() {
        if (this.props.user) {
            window.location.href = "/dashboard";
        } else {
            return (
                <div className={"auth"}>
                    {this.props.authTabletView}
                </div>
            )
        }
    }
}

export default connect(state => state, { loadUserInfo }) (Auth)