import {INITIAL_STORE_STATE} from "../initialStore";
import * as types from "../actionsTypes";

export default function modalReducer(state = INITIAL_STORE_STATE.modal, action) {
    switch (action.type) {
        case types.UPDATE_MODAL_STATE:
            return action.value
        default:
            return state;
    }
}