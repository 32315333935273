import React, {Component} from 'react';
import {createImagePath} from "../../../assets/functions/formatting";
import emptyDetector from "../../../assets/img/empty/detector.svg";
import settingsIcon from "../../../assets/img/settings.svg";
import {DETECTOR} from "../../../constants/objectTypes";
import {connect} from "react-redux";
import {updateModalState} from "../../../redux/actions/common/modal";
import {saveDetectorParameters} from "../../../assets/axios/requests/configuration/detectors";
import DetectorSettings from "../../configuration/common/detectorSettings";

class Item extends Component {
    render() {
        let { detector, withToolbar } = this.props;
        let image = createImagePath(detector, emptyDetector);
        detector.objectType = DETECTOR;

        return (
            <div className={`item ${this.props.className || ''}`} onClick={this.props.onClick || null}>
                <div className={"detector-icon img-container"}>
                    <img src={image}
                         alt={ detector?.name || "Неизвестный детектор" }/>
                </div>
                <div className={"info"}>
                    <div className={"name"}>{ detector?.name || "Неизвестный детектор" }</div>
                    <div className={"description"}>{ detector?.description || "" }</div>
                </div>

                {
                    withToolbar
                        ? <div className={"toolbar"}>
                            <img src={settingsIcon}
                                 alt={"settings"}
                                 title={"Настройки детектора"} onClick={() => this.props.updateModalState(
                                     true, <DetectorSettings detector={detector}
                                                 defaultOnly
                                                 onSubmit={values => saveDetectorParameters(detector.id, values)}/>)}/>
                        </div>
                        : null
                }
            </div>
        )
    }
}

export default connect(() => ({}), { updateModalState }) (Item);