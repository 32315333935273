import {UPDATE_MENU_STATE} from "../actionsTypes";
import {INITIAL_STORE_STATE} from "../initialStore";

export function updateMenuState() {
    let menuItems = INITIAL_STORE_STATE.menuItems.slice();

    menuItems.map(item => item.active = (item.path === window.location.pathname))

    return {
        type: UPDATE_MENU_STATE,
        value: menuItems
    }
}