import axios from 'axios';
import {EVENT, EVENTS} from "../URLs";

export function getEventsList(params) {
    return axios.get(EVENTS, {
        withCredentials: true,
        params
    });
}

export function getEventDetails(id) {
    return axios.get(EVENT(id), {
        withCredentials: true
    });
}