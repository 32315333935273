import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import PageWrapper from './wrappers/Page';
import Dashboard from "./components/dashboard";
import Configuration from "./components/configuration";
import Settings from "./components/settings";
import Database from "./components/database";
import Users from "./components/users";
import Stats from "./components/stats";
import Auth from "./components/auth";
import Events from "./components/events";
import Shop from "./components/shop";

class Router extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/auth`} children={<Auth />} />

                    <PageWrapper>
                        <Route exact path={`${process.env.PUBLIC_URL}/dashboard`} children={<Dashboard />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/events`} children={<Events />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/configuration`} children={<Configuration />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/settings`} children={<Settings />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/database`} children={<Database />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/users`} children={<Users />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/stats`} children={<Stats />} />
                        <Route exact path={`${process.env.PUBLIC_URL}/shop`} children={<Shop />} />
                    </PageWrapper>
                </Switch>
            </BrowserRouter>
        )
    }
}

export default Router;