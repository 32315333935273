import React, {Component} from 'react';
import AuthTablet from "../../../wrappers/AuthTablet";
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {authUser} from "../../../assets/axios/requests/user";
// import ForgetPassword from "./ForgetPassword";
import {connect} from "react-redux";
import {updateAuthTabletView} from "../../../redux/actions/auth";
import {getRequestAnswer} from "../../../assets/functions/request";

class Login extends Component {
    render() {
        const validationSchema = Yup.object().shape({
            email: Yup.string().email("E-mail некорректен").required("Заполните все поля"),
            password: Yup.string().required("Заполните все поля")
        });

        return (
            <AuthTablet header={"Вход в систему"}>
                <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={validationSchema}
                    validateOnChange={true}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        authUser(values)
                            .catch( error => actions.setFieldError('submit', error) );
                    }}
                >
                    {
                        props => {
                            return <Form>
                                <div className={"content"}>
                                    <Field type={"email"} name={"email"} placeholder={"E-mail"}/>
                                    <Field type={"password"} name={"password"} placeholder={"Пароль"}/>

                                    <ErrorMessage name={"email"}>
                                        {message => <div className={"warning"}>{message}</div>}
                                    </ErrorMessage>

                                    <ErrorMessage name={"password"}>
                                        {message => <div className={"warning"}>{message}</div>}
                                    </ErrorMessage>

                                    {
                                        props.errors.submit
                                            ? <div className={"warning"}>
                                                {getRequestAnswer(props.errors.submit.response
                                                    ? props.errors.submit.response.status
                                                    : null)}
                                            </div>
                                            : null
                                    }
                                </div>

                                <button type="submit" disabled={false}>Войти</button>
                            </Form>
                        }
                    }
                </Formik>

                {/*<div className={"forget-password"}*/}
                {/*     onClick={() => this.props.updateAuthTabletView(<ForgetPassword />)}>*/}
                {/*    Забыли пароль?</div>*/}
            </AuthTablet>
        )
    }
}

export default connect(() => ({}), { updateAuthTabletView }) (Login)