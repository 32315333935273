import React, {Component} from 'react';
import {connect} from "react-redux";
import {getNeuroboxesList} from "../../../assets/axios/requests/common/neuroboxes";
import {getEventTypes} from "../../../assets/axios/requests/common/eventTypes";
import {updateEventsFiltersNeurobox, updateEventsFiltersType} from "../../../redux/actions/events/settings/filters";
import FiltersItem from "../../common/page/settings/filters/FiltersItem";
import {updateEventsList} from "../../../redux/actions/common/events";
import {clearEventsTopOffset} from "../../../redux/actions/events";
import {updateEventsPortionCount} from "../../../redux/actions/events/portionCount";
import {handleError} from "../../../assets/functions/errors";
import {updateModalState} from "../../../redux/actions/common/modal";
import {EVENT_TYPE_LIST_IN_FILTERS, NEUROBOXES_LIST_IN_FILTERS} from "../../../constants/errors";

class Filters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            neuroboxes: [],
            eventTypes: [],
        }
    }

    componentDidMount() {
        let self = this;
        getNeuroboxesList()
            .then(
                response => self.setState({ neuroboxes: response.data }),
                error => handleError(error, NEUROBOXES_LIST_IN_FILTERS, this.props.updateModalState)
            );

        getEventTypes()
            .then(
                response => self.setState({ eventTypes: response.data }),
                error => handleError(error, EVENT_TYPE_LIST_IN_FILTERS, this.props.updateModalState)
            );
    }

    render() {
        let parameters = [
            {
                name: "Нейробокс",
                items: this.state.neuroboxes,
                selectedItem: this.props.neurobox,
                onSelect: (id, isSelected) => {
                    this.props.updateEventsFiltersNeurobox(isSelected ? null : id);
                    this.props.updateEventsList([]);
                    this.props.clearEventsTopOffset();
                    this.props.updateEventsPortionCount(0);
                }
            },
            {
                name: "Событие",
                items: this.state.eventTypes,
                selectedItem: this.props.type,
                onSelect: (id, isSelected) => {
                    this.props.updateEventsFiltersType(isSelected ? null : id);
                    this.props.updateEventsList([]);
                    this.props.clearEventsTopOffset();
                    this.props.updateEventsPortionCount(0);
                }
            }
        ]
        return (
            <FiltersItem parameters={parameters} />
        )
    }
}

export default connect(state => state.events.settings.filters,
    {
        updateEventsFiltersNeurobox,
        updateEventsFiltersType,
        updateEventsList,
        clearEventsTopOffset,
        updateEventsPortionCount,
        updateModalState
    }) (Filters);