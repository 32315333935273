import React, {Component} from 'react';
import {splitSettingsAndValues} from "../../../../../assets/functions/detectorSettings";
import { Formik } from 'formik';
import {
    clearDetectorParameters,
    getDetectorParameters
} from "../../../../../assets/axios/requests/configuration/detectors";
import Loader from "../../../../common/Loader";
import {connect} from "react-redux";
import {updateModalState} from "../../../../../redux/actions/common/modal";
import ConnectionSettings from "./settingsTypes/ConnectionSettings";
import DefaultSettings from "./settingsTypes/DefaultSettings";
import {handleError} from "../../../../../assets/functions/errors";
import {
    DETECTOR_ATTACHMENT,
    DETECTOR_PARAMETERS_CLEANING,
    DETECTOR_PARAMETERS_LIST
} from "../../../../../constants/errors";

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userDetectorValues: null
        }

        this.resetFormValues = this.resetFormValues.bind(this);
    }

    async componentDidMount() {
        try {
            let detectorParameters = await getDetectorParameters(this.props.detector.id);
            this.setState({
                userDetectorValues: detectorParameters.data?.parameters || {}
            });
        }
        catch (error) {
            handleError(error, DETECTOR_PARAMETERS_LIST, this.props.updateModalState);
        }
    }

    async resetFormValues(detectorId, resetForm) {
        try {
            await clearDetectorParameters(detectorId);
            this.setState({ userDetectorValues: {} })
            resetForm();
        }
        catch (error) {
            handleError(error, DETECTOR_PARAMETERS_CLEANING, this.props.updateModalState);
        }
    }

    render() {
        let { detector, defaultOnly, onSubmit, camera, isConnectionSettings } = this.props;
        let settings = null;
        for (let i=0; i< detector.detectorModels.length; i++){
            if(camera?.neurobox?.model?.code === detector.detectorModels[i].model.code){
                settings = detector.detectorModels[i];
            }
        }
        let detectorSettings = settings?.settings || [];

        let defaultDetectorValues = settings?.defaultSettings || {};
        let { userDetectorValues } = this.state;
        let formValues = Object.assign({}, defaultDetectorValues, userDetectorValues);

        if (isConnectionSettings) {
            formValues = Object.assign(formValues, camera.detectorParameters);
        }

        let [defaultSettings, connectionSettings, defaultFormValues] =
            splitSettingsAndValues(detectorSettings, formValues);

        return (
            userDetectorValues === null ? <Loader/>
            : <Formik
                    initialValues={defaultOnly ? defaultFormValues : formValues}
                    enableReinitialize={true}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            setSubmitting(false);
                            await onSubmit(values);
                            this.props.updateModalState(false);
                        }
                        catch(error) {
                            setSubmitting(true);
                            handleError(error, DETECTOR_ATTACHMENT, this.props.updateModalState);
                        }
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          resetForm,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} className={"detector-settings-form"}>
                            {!defaultOnly
                                ? <ConnectionSettings settings={connectionSettings} camera={camera}/>
                                : null}

                            <DefaultSettings settings={defaultSettings} defaultOnly={defaultOnly}/>

                            {settings ? (
                                <>
                                    { detectorSettings.length ? null : "Настройки отсутствуют" }

                                    <button type="submit" disabled={isSubmitting}>Сохранить</button>
                                    {
                                        defaultOnly
                                            ? <div className={"reset-settings"} onClick={() => {
                                                let confirm = window.confirm("Вы уверены, что хотите сбросить настройки?");
                                                if (confirm) {
                                                    this.resetFormValues(detector.id, resetForm);
                                                }
                                            }}>Сбросить настройки</div>
                                            : null
                                    }
                                </>
                            ) : (
                                `Невозможно добавить детектор к нейробоксу модели ${
                                    camera?.neurobox?.model?.name ||
                                    "неизвестная модель"
                                }`
                            )}
                        </form>
                    )}
                </Formik>
        )
    }
}

export default connect(() => ({}), { updateModalState }) (Form);