import React, {Component} from 'react';
import {connect} from "react-redux";
import {updateMenuState} from "../../redux/actions/menu";
import Content from "../../wrappers/Content";
import Neuroboxes from "./neuroboxes";
import Detectors from "./detectors";
import { DragDropContainer } from "react-draggable-hoc";
import Cameras from "./cameras";

class Configuration extends Component {
    componentDidMount() {
        this.props.updateMenuState();
    }

    render() {
        return (
            <>
                <div className={"settings-sidebar"}/>

                <div className={"configuration"}>
                    <Content>
                        <DragDropContainer>
                            <div className={"block-grid"}>
                                <Detectors/>
                                <Cameras/>
                                <Neuroboxes/>
                            </div>
                        </DragDropContainer>
                    </Content>
                </div>
            </>
        )
    }
}

export default connect(state => state, { updateMenuState }) (Configuration);