import { INITIAL_STORE_STATE } from "../initialStore";
import * as types from "../actionsTypes";

export default function configurationReducer(
    state = INITIAL_STORE_STATE.configuration,
    action
) {
    switch (action.type) {
        case types.UPDATE_DETECTORS_LIST:
            return Object.assign({}, state, {
                detectors: {
                    list: action.value,
                },
            });

        case types.UPDATE_CAMERAS_LIST:
            return Object.assign({}, state, {
                cameras: {
                    list: action.value,
                },
            });

        case types.UPDATE_NEUROBOXES_LIST:
            return Object.assign({}, state, {
                neuroboxes: {
                    list: action.value,
                },
            });

        case types.ADD_CAMERA:
            return Object.assign({}, state, {
                cameras: {
                    list: state.cameras.list.concat([action.value]),
                },
            });

        case types.DELETE_CAMERA:
            return Object.assign({}, state, {
                cameras: {
                    list: state.cameras.list.filter(
                        (camera) => camera.id !== action.value.id
                    ),
                },
            });

        case types.DELETE_NEUROBOX:
            return Object.assign({}, state, {
                neuroboxes: {
                    list: state.neuroboxes.list.filter(
                        (neurobox) => neurobox.id !== action.value.id
                    ),
                },
            });

        default:
            return state;
    }
}
