import React, {Component} from 'react';
import Block from "../../../wrappers/Block";
import {connect} from "react-redux";
import {loadNeuroboxesList} from "../../../redux/actions/common/neuroboxes";

class List extends Component {
    componentDidMount() {
        this.timer = setInterval(() => {
            this.props.loadNeuroboxesList();
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        let neuroboxes = this.props.list;

        return (
            <Block header={"Устройства Нейробокс"}>
                <div className="neuroboxes container">
                    {
                        neuroboxes.map(neurobox => this.props.children(neurobox))
                    }
                </div>
            </Block>
        )
    }
}

export default connect(
    state => state.configuration.neuroboxes,
    { loadNeuroboxesList }
    ) (List);