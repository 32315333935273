import React, {Component} from 'react';
import Block from "../../../wrappers/Block";
import {connect} from "react-redux";
import Item from "../../common/detectors/Item";
import test from "../../../assets/img/purchaseOptions/test.svg";
import subscribe from "../../../assets/img/purchaseOptions/subscribe.svg";
import purchase from "../../../assets/img/purchaseOptions/purchase.svg";

class Details extends Component {
    render() {
        let { details } = this.props;

        return (
            <Block header={"Информация о детекторе"}>
                <div className="detector-details container">
                    <Item detector={details}/>

                    {
                        details?.cases?.length
                            ? <div className={"detail-item"}>
                                <div className={"name"}>Кейсы применения</div>
                                <ul>
                                    {
                                        details.cases.map( (useCase, i) => <li key={i}>{ useCase }</li> )
                                    }
                                </ul>
                            </div>
                            : null
                    }

                    {
                        details?.requirements?.length
                            ? <div className={"detail-item"}>
                                <div className={"name"}>Технические требования</div>
                                <ul>
                                    {
                                        details.requirements.map( (requirement, i) => <li key={i}>{ requirement }</li> )
                                    }
                                </ul>
                            </div>
                            : null
                    }

                    <div className={"purchase-options"}>
                        <div className={"option"}>
                            <div className={"icon"}><img src={test} alt={"Тест"}/></div>
                            <div className={"description"}>
                                <div className={"name"}>тест</div>
                                <div className={"price"}>бесплатно</div>
                            </div>
                        </div>
                        <div className={"option"}>
                            <div className={"icon"}><img src={subscribe} alt={"Подписка"}/></div>
                            <div className={"description"}>
                                <div className={"name"}>подписка</div>
                                <div className={"price"}>500 руб. в мес.</div>
                            </div>
                        </div>
                        <div className={"option"}>
                            <div className={"icon"}><img src={purchase} alt={"Покупка"}/></div>
                            <div className={"description"}>
                                <div className={"name"}>покупка</div>
                                <div className={"price"}>5000 руб.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Block>
        )
    }
}

export default connect(state => state.shop.detectors) (Details);