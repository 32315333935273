import React, {Component} from 'react';
import {connect} from "react-redux";
import {updateMenuState} from "../../redux/actions/menu";

class Users extends Component {
    componentDidMount() {
        this.props.updateMenuState();
    }

    render() {
        return (
            <div>Hello, this is Users in neuro_cloud</div>
        )
    }
}

export default connect(state => state, { updateMenuState }) (Users)