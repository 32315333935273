import React, {Component} from 'react';
import Block from "../../../wrappers/Block";
import {connect} from "react-redux";
import emptyDetailShot from "../../../assets/img/empty/detail-shot.svg";
import {createImagePath} from "../../../assets/functions/formatting";
import {LOCALE} from "../../../constants/common";
import Row from "./Row";

class Details extends Component {
    render() {
        let { details } = this.props;
        let image = createImagePath(details, emptyDetailShot);
        let neurobox = details?.neurobox;
        let camera = neurobox?.camera;
        let cameraType = camera?.type;
        let detector = camera?.detector;
        let date = new Date( details?.time );
        let eventType = details?.type;
        let settingsConfig = eventType?.settings || {};
        let settings = details?.data || {};
        let cameraTypeName = cameraType === "web" ? "web-камера " : cameraType === "ip" ? "ip-камера " : "";
        let cameraState = camera === null ? "Камера не подключена" : cameraTypeName;

        let parameters = [];
        Object.keys(settings).forEach(key => {
            parameters.push({
                label: settingsConfig[key]?.label || "Неизвестный параметр",
                type: settingsConfig[key]?.type,
                value: settingsConfig[key]?.values === null
                    ? settings[key]
                    : settingsConfig[key]?.values[settings[key]]
            });
        });

        let rows = [
            {
                label: "Нейробокс",
                value: `${neurobox?.name || "Неизвестный неробокс"} (${neurobox?.ip || "Неизвестный адрес"})`
            },
            {
                label: "Камера",
                value: `${cameraState} ${camera?.name || "Неизвестная камера"} (${camera?.address || "Неизвестный адрес"})`
            },
            {
                label: "Детектор",
                value: `${detector?.name || "Неизвестный детектор"}`
            },
            {
                label: "Событие",
                value: `${eventType?.name || "Неизвестное событие"}`
            },
            {
                label: "Дата и время",
                value: <><span className={"time"}>{date.getTime() ? date.toLocaleTimeString(LOCALE) : ''}</span> {date.getDate() ? date.toLocaleDateString(LOCALE) : ''}</>
            },
            {
                label: "Описание",
                value: `${eventType?.description || "Нет описания"}`
            },
            ...parameters
        ]

        return (
            <Block header={"Информация о событии"} className={"details"}>
                <div className={"event-details container"}>
                    <img src={image}
                         className={"shot"}
                         alt={""}/>
                     <div className={"info"}>
                         {
                             rows.map( (row, i) => <Row {...row} key={i}/> )
                         }
                     </div>
                </div>
            </Block>
        )
    }
}

export default connect(state => state.events) (Details);