import {INITIAL_STORE_STATE} from "../initialStore";
import * as types from "../actionsTypes";

export default function menuReducer(state = INITIAL_STORE_STATE.menuItems, action) {
    switch (action.type) {
        case types.UPDATE_MENU_STATE:
            return action.value
        default:
            return state;
    }
}