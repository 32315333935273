import React, {Component} from 'react';
import List from "../../common/detectors/List";
import ClickableItem from "./ClickableItem";

class Detectors extends Component {
    render() {
        return (
            <List>
                {
                    detector => <ClickableItem detector={detector} key={detector.id}/>
                }
            </List>
        )
    }
}

export default Detectors